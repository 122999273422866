import {
  IRegistrationLoginParams,
  IRegistrationLoginPayload,
  IRegistrationSuccessResponse,
  IRegistrationUpdateParams,
  IRegistrationFetchParams,
  IRegistrationPlatformPayload,
  IRegistrationPlatformParams,
  IRegistrationUpdatePayload,
  IRegistrationSignupPayload,
} from 'typings/auth/services/registration';
import { IApiConfig } from 'typings/core/config/api';
import * as Castle from '@castleio/castle-js';
import * as Sentry from '@sentry/browser';

class RegistrationResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(public $resource: ng.resource.IResourceService, public API: IApiConfig) {
    this.resource = $resource(
      `${this.API.endpoint}/:flow/registrations/:id/:controller`,
      {},
      {
        signup: {
          method: 'POST',
          headers: { 'recaptcha-token': this.getToken },
        },
        completeWithCredential: {
          method: 'POST',
          params: { id: '@id', controller: 'complete_with_bearer_token' },
        },
      }
    );
  }

  signup(
    params: IRegistrationLoginParams,
    payload: IRegistrationSignupPayload
  ): ng.IPromise<IRegistrationSuccessResponse> {
    return this.resource.signup(params, payload).$promise;
  }

  getCastleObject = () => {
    try {
      const API_KEY = import.meta.env.VITE_KEY_CASTLE;
      return Castle.configure({ pk: API_KEY, timeout: 10000 });
    } catch (e) {
      return null;
    }
  };

  async update(
    params: IRegistrationUpdateParams,
    payload: IRegistrationUpdatePayload,
    fingerprintResult?: string
  ): Promise<IRegistrationSuccessResponse> {
    const isCastle = !!fingerprintResult && !!import.meta.env.VITE_KEY_CASTLE;

    const castle = !isCastle ? null : this.getCastleObject();

    const castleToken = await (async () => {
      if (castle) {
        try {
          const token = await castle?.createRequestToken();

          return token;
        } catch (e) {
          Sentry.captureMessage(`Castle Error: ${e}`);
          /** */
        }
      }
      return undefined;
    })();

    const resource = this.$resource(
      `${this.API.endpoint}/:flow/registrations/:id`,
      {},
      {
        save: {
          method: 'PATCH',
          headers: { 'Integrity-Signature': fingerprintResult, 'Castle-Signature': castleToken },
        },
      }
    );

    const data = await resource.save(params, payload).$promise;
    if (castle) {
      const castleData = {
        user: {
          id: data.registration.data?.user?.id,
          email: data.registration.data?.user?.email,
        },
        name: 'User Signup',
        values: {
          easyship_company_id: data.easyship_company_id,
        },
      };
      castle.form(castleData).then(() => undefined);
    }
    return data;
  }

  fetch(params: IRegistrationFetchParams): ng.IPromise<IRegistrationSuccessResponse> {
    return this.resource.get(params).$promise;
  }

  completeWithCredential(
    params: IRegistrationFetchParams
  ): ng.IPromise<IRegistrationSuccessResponse> {
    return this.resource.completeWithCredential(params).$promise;
  }

  platform(
    params: IRegistrationPlatformParams,
    payload: IRegistrationPlatformPayload
  ): ng.IPromise<IRegistrationSuccessResponse> {
    const resource = this.$resource(`${this.API.endpoint}/:platform/registrations/:id`);

    return resource.save(params, payload).$promise;
  }

  login(
    params: IRegistrationLoginParams,
    payload: IRegistrationLoginPayload
  ): ng.IPromise<IRegistrationSuccessResponse> {
    const mergeParams = { ...params, controller: 'login' };

    return this.resource.save(mergeParams, payload).$promise;
  }

  private getToken(req: any) {
    const { token } = req.params;
    delete req.params.token;
    return token;
  }
}

export { RegistrationResource };
