import React, { useRef, useState } from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import Input from '@/components/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button } from 'easyship-components';
import useCreateCourierAccountMutation from '@app/hooks/mutations/useCreateCourierAccountMutation';
import { API } from '@app/constants/api';
import { useUserSession } from '@app/contexts/providers/UserSessionProvider';

export interface CanadaPostFormValues {
  nickname: string;
  merchantNumber: string;
}

export interface ConnectCourierFormCanadaPostProps {
  editMode: boolean;
  nickname: string;
  onDelete: () => void;
  onBack: () => void;
}

export function ConnectCourierFormCanadaPost({
  editMode,
  nickname,
  onBack,
  onDelete,
}: ConnectCourierFormCanadaPostProps) {
  const EASYSHIP_PLATFORM_ID = '9661954'; // ID that assigned by Canada Post to Easyship
  const trustedUrl = new URL('https://www.canadapost-postescanada.ca/information/app/drc/merchant');
  const { formatMessage } = useIntl();
  const {
    user: { isConnectLYOCUserActionEnabled },
  } = useUserSession();
  const { formState, handleSubmit } = useFormContext<CanadaPostFormValues>();
  const { mutateAsync: createCourierAccount, isLoading } = useCreateCourierAccountMutation();
  const [error, setError] = useState<string | null>(null);
  const [tokenId, setTokenId] = useState<string>('');
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const formRef = useRef<HTMLFormElement | null>(null);

  const CANADA_COUNTRY_ID = 39;

  // Rules
  const accountNicknameRules = {
    required: true,
    minLength: 3,
    maxLength: 15,
  };

  const onSubmit: SubmitHandler<CanadaPostFormValues> = async (form: CanadaPostFormValues) => {
    const payload = {
      umbrella_name: 'canada-post',
      account: {
        nickname: nickname || form.nickname,
        merchant_number: form.merchantNumber,
        origin_country_id: CANADA_COUNTRY_ID,
      },
    };

    const requiredFields = {
      nickname: nickname || form.nickname,
      merchantNumber: form.merchantNumber,
    };

    if (Object.values(requiredFields).some((value) => !value)) {
      setError(formatMessage({ id: 'toast.incomplete-form' }));
      return;
    }

    setError(null);

    try {
      const { id, canada_post_token_id: canadaPostTokenId } = await createCourierAccount(payload);

      if (!id || !canadaPostTokenId) {
        setError(formatMessage({ id: 'courier.connect.missing-parameters' }));
        return;
      }

      setTokenId(canadaPostTokenId);
      setRedirectUrl(`${API.dashboard}/couriers/${id}/redirect`);

      // Submit the hidden form after 1 second
      setTimeout(() => {
        if (formRef.current) {
          formRef.current.submit();
        }
      }, 1000);
    } catch (error) {
      setError(error?.data?.status || formatMessage({ id: 'toast.default-error' }));
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="!pt-[15px] !pl-[50px] !pb-5 !pr-[50px]">
          <div className="!mb-[10px]">
            <Controller
              name="nickname"
              rules={accountNicknameRules}
              defaultValue={nickname || ''}
              disabled={!!nickname}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  label={<FormattedMessage id="courier.connect.field.account-name.label" />}
                  placeholder={formatMessage({
                    id: 'courier.connect.field.account-name.placeholder',
                  })}
                  helperText={formatMessage({ id: 'courier.connect.field.account-name.help' })}
                  error={!!fieldState.error}
                  maxLength={accountNicknameRules.maxLength}
                  minLength={accountNicknameRules.minLength}
                />
              )}
            />
            <div className="!mt-[15px]">
              <Controller
                name="merchantNumber"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    value={field.value || ''}
                    label={<FormattedMessage id="courier.connect.field.merchant-number.label" />}
                    error={!!fieldState.error}
                  />
                )}
              />
            </div>
            {error && (
              <Alert severity="error" className="mt-4">
                {error}
              </Alert>
            )}
          </div>
        </div>
        <div className="border-b border-b-sky-300" />
        <div className="flex justify-between items-center px-12 pt-5 !pb-5">
          {editMode && (
            <Button color="danger" type="button" onClick={onDelete}>
              <FormattedMessage id="global.delete" />
            </Button>
          )}
          {!editMode && (
            <Button color="default" flat type="button" onClick={onBack}>
              <FormattedMessage id="global.back" />
            </Button>
          )}
          <Button
            color="primary"
            type="submit"
            className="min-w-[100px]"
            loading={isLoading}
            disabled={isLoading || !isConnectLYOCUserActionEnabled() || !formState.isValid}
          >
            <FormattedMessage id="global.connect" />
          </Button>
        </div>
      </form>
      {/* Hidden form for submitting to Canada Post */}
      <form ref={formRef} method="POST" action={trustedUrl.href} style={{ display: 'none' }}>
        <input type="text" name="language" value="en-CA" readOnly />
        <input type="text" name="token-id" value={tokenId} readOnly />
        <input type="text" name="platform-id" value={EASYSHIP_PLATFORM_ID} readOnly />
        <input type="text" name="return-url" value={redirectUrl} readOnly />
      </form>
    </div>
  );
}
