import template from './login.html?raw';

(function () {
  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('home.auth.login', {
        url: 'login',
        template,
        controller: 'LoginCtrl',
        controllerAs: 'vm',
        metadata: {
          title: 'Easyship Login | Smart Shipping Dashboard',
          description:
            'Login to your Easyship account to manage, ship and track your international eCommerce orders. Start shipping today with the the cheapest, fastest and best value for money shipping deals!',
        },
        resolve: {
          session: [
            '$q',
            'UserSession',
            ($q, UserSession) => {
              if (!UserSession.company) {
                UserSession.redirectToLogin();
              }
            },
          ],
        },
        params: {
          userEmail: null,
          accountClosed: null,
        },
      });
    },
  ]);
})();
