import { SUPPORT_HELP_LINK } from '@/constants/general';
import { IUserSession } from '@typings/user-session';
import angular from 'angular';
import { snakeToCamelCase } from '@/utils/snakeCamelTransform';
import { UserSession } from '@/hooks/queries/useGetUserSessionQuery/types';

export const isSameBarCode = (a: string | null, b: string | null) => {
  if (!a || !b) return false;
  return a.toUpperCase() === b.toUpperCase();
};

export const contactSupport = () => {
  window.open(SUPPORT_HELP_LINK, '_blank');
};

export const getUserSession = () => {
  const service: IUserSession = angular.element(document.body).injector().get('UserSession');
  return snakeToCamelCase({
    company: service?.company,
    user: service?.user,
  }) as UserSession;
};

export const hasUserDirectPrintEnabled = () => {
  const { user, company } = getUserSession() as Required<UserSession>;
  const isFeatureFlagDirectPrintNode = company?.featureFlags.directPrintNodeFeature;
  return user?.printingSettings?.directPrintEnabled && isFeatureFlagDirectPrintNode;
};
