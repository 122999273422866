export enum EcommercePlatform { // platform.slug
  Amazon = 'amazon',
  BigCommerce = 'bigcommerce',
  OldCashier = 'bold_cashier',
  BoldCashier = 'cashier',
  Brightpearl = 'brightpearl',
  BuyOnGoogle = 'buy-on-google',
  ChannelAdvisor = 'channeladvisor',
  Cratejoy = 'cratejoy',
  Dear = 'dear-inventory',
  eBay = 'ebay',
  Ecwid = 'ecwid',
  Etsy = 'etsy',
  Groupon = 'groupon',
  HKTDC = 'hktdc',
  Jumia = 'jumia',
  Jumper = 'jumper',
  Lazada = 'lazada',
  Linio = 'linio',
  Linnworks = 'linnworks',
  Magento = 'magento', // formerly Magento2
  MatahariMall = 'matahari-mall',
  NetSuite = 'netsuite',
  OnBuy = 'onbuy',
  Sellbrite = 'sellbrite',
  Shopee = 'shopee',
  Shopify = 'shopify',
  ShopifyPlus = 'shopify-plus',
  Shopmatic = 'shopmatic',
  Shopline = 'shopline',
  Shoptiq = 'shoptiq',
  SkuVault = 'sku-vault',
  Square = 'square',
  Squarespace = 'squarespace',
  TikTok = 'tiktok',
  TradeGecko = 'tradegecko',
  WalMart = 'walmart',
  Wix = 'wix',
  Weebly = 'weebly',
  WooCommerce = 'woocommerce',
  Zalora = 'zalora',
  Zapier = 'zapier',
  ZohoInventory = 'zoho-inventory',
}

export enum CrowdfundingPlatform {
  BackerKit = 'backerkit',
  CrowdOx = 'crowdox',
  Indiegogo = 'indiegogo',
  Kickstarter = 'kickstarter',
}

export enum OtherChannel {
  API = 'api',
  B2B = 'b2b',
  DirectSales = 'directsales',
  UberFreight = 'uber-freight',
}

export enum OtherCases {
  None = 'none_of_listed',
  Skip = 'selection_skipped',
}

export type Platform = EcommercePlatform | CrowdfundingPlatform | OtherChannel | OtherCases;

export type IPlatformConfig = { [key in Platform]?: string };

export const NoPlatform = 'do not sell online';
export const OtherPlatform = 'others';

export const fallbackKey = OtherChannel.DirectSales;
export const platformKeys = (Object.values(EcommercePlatform) as string[])
  .concat(Object.values(CrowdfundingPlatform))
  .concat(Object.values(OtherChannel));
