import addressBookImageUrl from '@assets/images/dashboard/create-shipments/address-book.svg';
import { getLocationPathName } from '@app/utils/routing';
import template from './receiver-form.html?raw';
import style from './receiver-form.module.scss';

class ReceiverFormCtrl {
  addressBookImageUrl = addressBookImageUrl;

  static $inject = [
    '$http',
    '$q',
    '$scope',
    'API',
    'MixpanelService',
    'UserSession',
    'CountryService',
    'RestrictionsService',
    'PostalCodeService',
    'CheckResidentialService',
    'AddressService',
  ];

  constructor(
    $http,
    $q,
    $scope,
    API,
    MixpanelService,
    UserSession,
    CountryService,
    RestrictionsService,
    PostalCodeService,
    CheckResidentialService,
    AddressService
  ) {
    this.style = style;
    this.$http = $http;
    this.$q = $q;
    this.$scope = $scope;
    this.API = API;
    this.MixpanelService = MixpanelService;
    this.UserSession = UserSession;
    this.CountryService = CountryService;
    this.RestrictionsService = RestrictionsService;
    this.PostalCodeService = PostalCodeService;
    this.CheckResidentialService = CheckResidentialService;
    this.AddressService = AddressService;
    this.countriesCanFetchState = ['US', 'NZ', 'CA', 'MX', 'AU', 'PR'];
    this.canFetchState = true;

    this.AU_ID = 14;
    this.HK_ID = 96;
    this.US_ID = 234;
    this.BR_ID = 32;
    this.NZ_ID = 172;

    this.countries = [];
    this.cityOptions = [];
    this.australianSuburbs = [];
    this.australianStates = [];
    this.defaultPickupAddress = null;
    this.busy = {
      gettingTaxIdRestriction: false,
    };

    this.destinationNeedsPostalCodeHasRegexToValidate = true;
    this.maxLength = {
      destination_name: 50,
      destination_company_name: 50,
      phone_number: 50,
      email_address: 100,
      address_line_1: 100,
      address_line_2: 100,
      city: 100,
      postal_code: 20,
      state: 100,
    };

    this.messages = {
      showEmptyPostalCode: false,
      showRegexMismatch: false,
      showInvalidPostalCode: false,
      showMismatchedPostalCode: false,
    };

    this.previousOriginCountryId = null;
    this.isDomesticShipment = false;
    this.taxIdRequirements = {
      require: 'no_need',
      regexes: [],
    };
    this.isTaxIdFieldValid = false;

    this.typeaheadOptions = {};
    this.typeaheadTemplate = `
        <span>
          {{%destination_name}},
          {{%destination_company_name ? %destination_company_name + ', ' : '' }}
          {{%phone_number}},
          {{%email_address}}
        </span>
        <span>
          {{%address_line_1}},
          {{%address_line_2 ? %address_line_2 + ', ' : '' }}
          {{%city}},
          {{%state ? %state + ', ' : '' }}
          {{%postal_code}},
          {{%destination_country_name}}
        </span>
      `;
  }

  $onInit() {
    this.previousOriginCountryId = this.shipment.origin_country_id;

    this.$q
      .all([
        this.CountryService.getValidReceiverCountries(),
        this.CountryService.getUSStates(),
        this.AddressService.getAddresses(),
      ])
      .then((data) => {
        this.countries = angular.copy(data[0].countries);
        this.defaultPickupAddress = this.AddressService.getDefaultPickupAddress();
        this.shipment.destination_country = this._getCountry(this.shipment, {
          editMode: this.editMode,
        });
        this.shipment.destination_country_id = this._assignCountryID(this.shipment);
        this._checkIfPostalCodeIsRequiredForDestination(this.shipment);
        this.canFetchState = this.countriesCanFetchState.includes(
          this.shipment.destination_country.alpha2
        );

        if (this.shipment.postal_code) {
          this._validatePostalCode(this.shipment, { onUpdate: true });
        }

        this._checkDomesticShipmentStatus().then((isDomesticShipment) => {
          this.isDomesticShipment = isDomesticShipment;
          this._checkTaxIdRestrictions();
        });
        if (this.UserSession.isLuxUser() && this.shipment.origin_address) {
          this.shipment.destination_country = this.CountryService.findCountry(
            this.shipment.origin_address.country_id
          );
        }
      });
  }

  $doCheck() {
    if (
      !this.shipment.origin_country_id ||
      this.shipment.origin_country_id === this.previousOriginCountryId
    )
      return;

    this._checkDomesticShipmentStatus().then((isDomesticShipment) => {
      this.isDomesticShipment = isDomesticShipment;
      this._checkTaxIdRestrictions();
    });

    this.previousOriginCountryId = this.shipment.origin_country_id;

    let previousSelectedCountryId = '';
    if (
      this.UserSession.isLuxUser() &&
      !angular.equals(this.shipment.origin_address.country_id, previousSelectedCountryId)
    ) {
      this.shipment.destination_country = this.CountryService.findCountry(
        this.shipment.origin_address.country_id
      );
      previousSelectedCountryId = angular.copy(this.shipment.origin_address.country_id);
    }
  }

  onInputChange(value, model) {
    this.shipment[model] = value;

    if (
      this._shipmentHasFieldsToCheckResidential(this.shipment) &&
      !this.busy.residential &&
      model !== 'set_as_residential'
    ) {
      this.busy.residential = true;

      const destination_country_id =
        (this.shipment.destination_country && this.shipment.destination_country.id) ||
        this.shipment.destination_country_id;

      this.CheckResidentialService.getAddressResidentialStatus({
        address: {
          ...this.shipment,
          destination_country_id,
        },
      })
        .then((status) => {
          this.shipment.identified_as_residential = status;

          // Also switch the toggle for the user
          //
          // NOTE: TEMP disabled until further notice
          // this.shipment.set_as_residential = status;
        })
        .finally(() => {
          this.busy.residential = false;
        });
    }

    if (model === 'postal_code') {
      this.shipment.city = '';
      this.shipment.state = '';
      this._checkIfPostalCodeIsRequiredForDestination(this.shipment);
      this._validatePostalCode(this.shipment, { onUpdate: true });
      this.resetSimplifiedDomesticValue();
    }

    if (model === 'phone_number') {
      this.shipment[model] = this._formatContactPhone(this.shipment[model]);
    }
  }

  resetSimplifiedDomesticValue() {
    if (!this.isSimplifiedFlow) return;
    this.shipment.contains_battery_pi967_user_input = null;
    this.shipment.contains_liquids_user_input = null;
  }

  onValidityChange(valid) {
    this.isTaxIdFieldValid = valid;
  }

  onPostalCodeBlur() {
    this._validatePostalCode(this.shipment, { enableErrorMessage: true });
  }

  onTypeaheadQuery(value, keywordSearchField) {
    if (!this.shouldShowSuggestions) return;

    this.AddressService.suggest(value, keywordSearchField).then((suggestedAddresses) => {
      this.typeaheadOptions[keywordSearchField] = suggestedAddresses.map((address) => ({
        ...address,
        destination_country_name: this.CountryService.findCountryName(
          address.destination_country_id
        ),
      }));
    });
  }

  onTypeaheadSelect(value) {
    this.shipment = angular.merge(this.shipment, value);
    this.shipment.destination_country = this.countries.find((country) => {
      return country.id === value.destination_country_id;
    });
    this._checkIfPostalCodeIsRequiredForDestination(this.shipment);
    this._toggleErrorMessage();
    this._normalizeAuPostalCodeAutofillResponse();

    this._checkDomesticShipmentStatus().then((isDomesticShipment) => {
      this.isDomesticShipment = isDomesticShipment;
      this._checkTaxIdRestrictions();
    });

    this.typeaheadOptions = {};
  }

  _formatContactPhone(phone = '') {
    // Remove spaces . and -
    return phone.replace(/[.\-\s]+/g, '');
  }

  _destinationUsesResidentialAPI(shipment = {}) {
    return this.CheckResidentialService.destinationUsesResidentialAPI(
      (shipment.destination_country && shipment.destination_country.id) ||
        shipment.destination_country_id
    );
  }

  _shipmentAddressIsValid(shipment = {}) {
    return shipment.is_valid_address;
  }

  showResidentialMessages(shipment = {}) {
    return this._shipmentAddressIsValid(shipment) && this._destinationUsesResidentialAPI(shipment);
  }

  // {
  //   "address_line_1": "address line 1",
  //   "address_line_2": "address line 2",
  //   "city": "New York",
  //   "destination_country_id": 234,
  //   "postal_code": "10001",
  //   "state": "New York"
  // }
  // Ensure the form has been filled with a minimum of key fields to trigger a request
  _shipmentHasFieldsToCheckResidential(shipment = {}) {
    if (
      !shipment.destination_country ||
      !shipment.destination_country.id ||
      !this._destinationUsesResidentialAPI(shipment)
    ) {
      return false;
    }

    const fieldsRequiredToCkeck = ['address_line_1', 'postal_code', 'city'];
    let isCheckable = true;

    fieldsRequiredToCkeck.forEach((field) => {
      if (!shipment[field]) {
        isCheckable = false;
      }
    });

    return isCheckable;
  }

  onSelectChange(value, model) {
    this.shipment[model] = value;

    if (model === 'destination_country') {
      this._onCountryChange(this.shipment);
    }
  }

  _checkDomesticShipmentStatus() {
    return this.$q((resolve) => {
      const { origin_address = {}, origin_address_id, destination_country = {} } = this.shipment;

      if (origin_address.country_id && destination_country.id) {
        resolve(destination_country.id === origin_address.country_id);

        return;
      }

      if (origin_address_id && destination_country.id) {
        this.AddressService.queryById(origin_address_id).then((address) => {
          resolve(destination_country.id === address.country_id);
        });

        return;
      }

      resolve(false);
    });
  }

  isDestinationUnitedStates() {
    return this.shipment.destination_country && this.shipment.destination_country.id === this.US_ID;
  }

  getBackendError(field) {
    if (!this.errorLogs) return '';
    return this.errorLogs[field];
  }

  _onCountryChange(shipment) {
    this._clearStatesInput();
    this._clearCityInput();
    this._toggleErrorMessage();
    this.canFetchState = this.countriesCanFetchState.includes(shipment.destination_country.alpha2);

    shipment.destination_country_id = this._assignCountryID(shipment);

    this._checkDomesticShipmentStatus().then((isDomesticShipment) => {
      this.isDomesticShipment = isDomesticShipment;

      // Remove the pay on scan if the shipment is none US domestic
      if (shipment.destination_country_id !== this.US_ID && !isDomesticShipment) {
        shipment.automated_return_requested = false;
      }

      this._checkTaxIdRestrictions();
    });

    if (shipment.postal_code) this._clearPostalCodeInput();
  }

  _assignCountryID(shipment) {
    if (!shipment.destination_country || !shipment.destination_country.hasOwnProperty('id'))
      return null;
    return shipment.destination_country.id;
  }

  _validatePostalCode(shipment, options = {}) {
    this.PostalCodeService.isPostalCodeRequiredForDestination(shipment).then(
      (postalCodeRequiredForDestination) => {
        if (
          this.PostalCodeService.doesCountryHavePostalCodeRegex(shipment) &&
          shipment.postal_code
        ) {
          this.destinationNeedsPostalCodeHasRegexToValidate = true;
          this._checkIfPostalCodeMatchesRegex(shipment, options.enableErrorMessage);
        } else {
          this.destinationNeedsPostalCodeHasRegexToValidate = false;
        }

        if (options.onUpdate) {
          this._showAlertMessageIfPostalCodeIsMissing(
            shipment.postal_code,
            postalCodeRequiredForDestination
          );
        }
      }
    );
  }

  _checkIfPostalCodeIsRequiredForDestination(shipment) {
    this.destinationNeedsPostalCode = this.PostalCodeService.isCountryStateInDatabase(shipment);
  }

  _checkIfPostalCodeMatchesRegex(shipment, enableErrorMessage) {
    if (this.PostalCodeService.doesPostalCodeMatchRegex(shipment)) {
      this.messages.showRegexMismatch = false;

      if (this.destinationNeedsPostalCode && !enableErrorMessage) {
        const doesCountrySupportFindState = this.countriesCanFetchState.includes(
          shipment.destination_country.alpha2
        );
        if (doesCountrySupportFindState) {
          this._findStateWithPostalCode(shipment);
        } else {
          this.canFetchState = false;
        }
      }
    } else {
      if (shipment.destination_country.id === this.NZ_ID) this._clearCityInput();
      if (this.destinationNeedsPostalCode) this._clearStatesInput();
      if (enableErrorMessage) this._toggleErrorMessage('showRegexMismatch');
    }
  }

  _showAlertMessageIfPostalCodeIsMissing(postalCode, postalCodeRequiredForDestination) {
    if (postalCodeRequiredForDestination && !postalCode) {
      this._toggleErrorMessage('showEmptyPostalCode');
    } else if (!postalCodeRequiredForDestination) {
      this.messages.showEmptyPostalCode = false;
    }
  }

  _toggleErrorMessage(target) {
    Object.keys(this.messages).forEach((key) => {
      this.messages[key] = target === key;
    });
  }

  _findStateWithPostalCode(shipment) {
    this.busy.findingState = true;
    this.cityOptions = [];

    const params = {
      postal_code: shipment.postal_code,
      country_id: shipment.destination_country.id,
    };

    this.PostalCodeService.getState(params)
      .then((res) => {
        if (params.country_id === this.AU_ID) {
          this._processAUPostalCodeResponse(res);
        } else {
          this._toggleErrorMessage();

          if (res.state) {
            if (
              this.shipment.state &&
              this.shipment.state.toLowerCase() !== res.state.name.toLowerCase()
            ) {
              this._toggleErrorMessage('showMismatchedPostalCode');
            } else {
              this.shipment.state = res.state.name;
            }
          }

          if (res.city) {
            if (this.shipment.city && this.shipment.city.toLowerCase() !== res.city.toLowerCase()) {
              this._toggleErrorMessage('showMismatchedPostalCode');
            } else {
              this.shipment.city = res.city;
            }
          }

          if (res.cities && res.cities.length > 0) {
            this.cityOptions = res.cities;

            if (this.shipment.city) {
              const matchedCity = this.findCityCaseInsensitive(res.cities, this.shipment.city);
              if (this.shipment.city && !matchedCity) {
                this._toggleErrorMessage('showMismatchedPostalCode');
              }
              // eslint-disable-next-line prefer-destructuring
            } else this.shipment.city = res.cities[0];
          }

          this.busy.findingState = false;
        }
      })
      .catch(() => {
        this.busy.findingState = false;
        this.shipment.city = '';
        this.shipment.state = '';
        this._toggleErrorMessage('showInvalidPostalCode');
      });
  }

  _processAUPostalCodeResponse(res) {
    this.australianSuburbs = [];
    this.australianStates = [];

    if (res.suburbs.length > 0 && res.states.length > 0) {
      this._toggleErrorMessage();
      this.australianSuburbs = res.suburbs;
      this.australianStates = res.states;

      if (this.shipment.city) {
        const matchedCity = this.findCityCaseInsensitive(
          this.australianSuburbs,
          this.shipment.city
        );

        if (matchedCity) {
          this.shipment.city = matchedCity;
        }
        if (!matchedCity) {
          this.australianSuburbs.unshift(this.shipment.city);
          this._toggleErrorMessage('showMismatchedPostalCode');
        }
      } else {
        this.shipment.city = this.australianSuburbs[0];
      }

      if (!this.shipment.state) {
        this.shipment.state = this.australianStates[0].name;
      } else {
        const findStateName = this.australianStates.find(
          (state) => state.abbr === this.shipment.state || state.name === this.shipment.state
        );
        this.shipment.state = findStateName.name ?? '';
      }

      this.busy.findingState = false;
    } else {
      this.busy.findingState = false;
      this._clearStatesInput();
      this._toggleErrorMessage('showInvalidPostalCode');
    }
  }

  _clearStatesInput() {
    this.shipment.state = null;
  }

  _clearPostalCodeInput() {
    this.shipment.postal_code = null;
  }

  _clearCityInput() {
    this.shipment.city = null;
  }

  _getCountry(shipment, options = {}) {
    let countryId =
      (shipment.destination_country && shipment.destination_country.id) ||
      shipment.destination_country_id;

    if (!options.editMode && !countryId) {
      countryId = this.defaultPickupAddress
        ? this.defaultPickupAddress.country_id
        : this.UserSession.company.country_id;
    }

    return this.countries.find((country) => {
      return country.id === countryId;
    });
  }

  _shipmentHasPostalCode(shipment) {
    return shipment.postal_code;
  }

  _checkTaxIdRestrictions() {
    if (this.isDomesticShipment || !this.shipment.destination_country) {
      this.shipment.consignee_tax_id = '';

      return;
    }

    this.busy.gettingTaxIdRestriction = true;

    this.RestrictionsService.getShipmentRestrictions({
      courier_id: this.shipment.courier_id,
      origin_country_id: this.shipment.origin_country_id,
      destination_country_id: this.shipment.destination_country.id,
    })
      .then(({ shipment }) => {
        this.taxIdRequirements = shipment.destination_address.consignee_tax_id;
      })
      .finally(() => {
        this.busy.gettingTaxIdRestriction = false;
      });
  }

  _normalizeAuPostalCodeAutofillResponse() {
    if (this.shipment.destination_country && this.shipment.destination_country.alpha2 === 'AU') {
      this._validatePostalCode(this.shipment, { enableErrorMessage: false });
    }
  }

  findCityCaseInsensitive(cities, city) {
    return cities.find((c) => c.toLowerCase() === city.toLowerCase());
  }

  get shouldShowSuggestions() {
    return this.UserSession.company.dashboard_settings.show_receiver_address_suggestions;
  }

  get prependTooltip() {
    if (!this.shouldShowSuggestions) return '';
    return 'shipments.create-shipment.prefill-address-tooltip';
  }

  get isBasicReceiverInfoPage() {
    const route = getLocationPathName();
    return !!route.includes('basic/receiver-info');
  }

  get isSimplifiedFlow() {
    const flag = this.UserSession.showSimplifiedDomesticFlow();
    if (!flag) return false;
    return this.isBasicReceiverInfoPage;
  }

  get isUSZipNineDigitFormatCode() {
    const countryId = this.shipment?.destination_country?.id;
    const postalCode = this.shipment?.postal_code;
    const state = this.shipment?.state;
    return countryId === this.US_ID && !!state && /\d{5}-\d{4}/.test(postalCode);
  }

  get isContactNumberOptional() {
    return this.isSimplifiedFlow;
  }

  get isEmailOptional() {
    if (this.isDomesticShipment) return true;
    return this.isSimplifiedFlow;
  }
}

const ReceiverFormComponent = {
  template,
  controller: ReceiverFormCtrl,
  bindings: {
    shipment: '=',
    errorLogs: '<',
    editMode: '<',
  },
};

export { ReceiverFormComponent };
