import { UserSessionProvider } from '@app/contexts/providers/UserSessionProvider';
import { SubscriptionTableCardProps } from '@app/pages/couriers/components/ChangePlanIntegrationComponent/SubscriptionTableCard/types';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { SubscriptionServiceProvider } from '@client/src/global/context/SubscriptionProvider';
import React, { lazy, Suspense } from 'react';
import { react2angular } from 'react2angular';

const SubscriptionTableCard = lazy(
  () =>
    import('@app/pages/couriers/components/ChangePlanIntegrationComponent/SubscriptionTableCard')
);

function WrappedComponent(props: SubscriptionTableCardProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <SubscriptionServiceProvider>
          <Suspense fallback={null}>
            <SubscriptionTableCard {...props} />
          </Suspense>
        </SubscriptionServiceProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularSubscriptionTableCardComponent = react2angular(WrappedComponent, [
  'isOpen',
  'toggleState',
  'onClose',
  'onToggleChange',
  'tableHeaderAction',
]);
