/* eslint-disable no-bitwise */
import angular from 'angular';
import moment from 'moment';
import dayjs from '@client/core/plugins/dayjs';

import { formatHsCodeNumber } from '@client/core/corelogic/models/HsCode';

class HelperService {
  static $inject = [
    '$compile',
    '$rootScope',
    '$window',
    '$document',
    '$http',
    'DeviceService',
    '$state',
  ];

  constructor($compile, $rootScope, $window, $document, $http, DeviceService, $state) {
    this.$compile = $compile;
    this.$rootScope = $rootScope;
    this.$window = $window;
    this.$document = $document;
    this.$http = $http;
    this.DeviceService = DeviceService;
    this.$state = $state;

    $rootScope.$on('$locationChangeSuccess', () => {
      window.dispatchEvent(new Event('angularPathNameChange'));
    });
  }

  doesKeyValueExists(obj, path) {
    return _.get(obj, path);
  }

  routeTo(destination, params, options) {
    return this.$state.go(destination, params, options);
  }

  spacesToKebabCase(string) {
    return string.split(' ').join('-').toLowerCase();
  }

  toCamelCase(string) {
    return _.camelCase(string);
  }

  snakeToKebabCase(string) {
    return string.replace(/[_]/g, '-');
  }

  snakeToDotNotation(string) {
    return typeof string === 'string' ? string.replace(/[_]/g, '.') : string;
  }

  kebabToSnakeCase(string) {
    return string.replace(/[-]/g, '_');
  }

  pascalToKebabCase(string) {
    return (
      string[0].toLowerCase() +
      string.slice(1, string.length).replace(/[A-Z]/g, (letter) => {
        return `_${letter.toLowerCase()}`;
      })
    );
  }

  capitalize(string) {
    return typeof string === 'string' ? string.charAt(0).toUpperCase() + string.slice(1) : string;
  }

  capitalizeAllCaps(string) {
    return typeof string === 'string'
      ? string.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
      : string;
  }

  /**
   * [preventNewLineInString] Ensures that /n in string doesn't get converted to a newline
   * @param  {String} string
   * @return {String}
   */
  preventNewLineInString(string = '') {
    return JSON.stringify(string).replace(/"/g, '');
  }

  getFirstLetter(string) {
    return string ? string.slice(0, 1) : '';
  }

  getId(obj) {
    return obj.id || obj.flat_rate_box_id;
  }

  getName(obj) {
    return obj.name;
  }

  hasItemWithId(arr, id) {
    return arr.map(this.getId).indexOf(id) >= 0;
  }

  hasItemWithName(arr, name) {
    return arr.map(this.getName).indexOf(name) >= 0;
  }

  getSplicedArray(array, max) {
    return array ? array.splice(0, max) : [];
  }

  compileAndAppendTemplateToTarget(template, targetId, scope) {
    const targetEl = angular.element(targetId);
    const newScope = Object.assign(this.$rootScope.$new(true), { $ctrl: scope });
    const compiledTemplate = this.$compile(template)(newScope)[0];

    targetEl.append(compiledTemplate);
  }

  /**
   * [doesObjHaveKeys] Check whether obj has keys
   * @param {Object} obj The object being inspected
   * @param {string[]} keysToOmit An array with keys to skip checking
   * @return {Boolean} Whether the given object has keys other than the given omitted keys
   */
  doesObjHaveKeys(obj, pathsToOmit) {
    return (
      Object.keys(obj).filter((key) => {
        return (pathsToOmit || []).indexOf(key) === -1;
      }).length > 0
    );
  }

  copyToClipboard(string) {
    const temporaryTextArea = document.createElement('textarea');
    temporaryTextArea.setAttribute('id', 'copy-target');
    document.body.appendChild(temporaryTextArea);
    document.getElementById('copy-target').value = string;
    temporaryTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(temporaryTextArea);
  }

  /**
   * [areObjKeyLengthsNil] Checks whether obj keys are nil
   * @param {Object} obj The object being inspected
   * @return {Boolean} Whether the given obj keys all have a value of nil
   */
  areObjKeyLengthsNil(obj) {
    return Object.values(obj).every((value) => !value);
  }

  sanitizeStringToInteger(element) {
    const sanitizedInteger = parseInt(element, 10) || NaN;
    if (this.$window.isNaN(sanitizedInteger)) return null;
    return sanitizedInteger;
  }

  /**
   * @param num The number to round
   * @param precision The number of decimal places to preserve
   */
  roundUp(num, precision) {
    const p = 10 ** precision;
    return Math.ceil(num * p) / p;
  }

  /**
   * Alternative to $window.location.assign where it will fix the issue where
   * future requests status become rejected for safari
   * https://stackoverflow.com/a/9834261/1123245
   * @param string link
   * @param string fileName
   */
  download(link, fileName) {
    const file = fileName || link.split('/').pop();

    // Check if browser is safari
    // https://stackoverflow.com/a/23522755/1123245
    const isSafari = /^((?!chrome|android).)*safari/i.test(this.$window.navigator.userAgent);

    // Fix issue desktop safari where user can see some error message after downloading the label
    if (isSafari && this.DeviceService.isMacintosh()) {
      this.$http
        .get(link, {
          responseType: 'blob',
        })
        .then(({ data }) => {
          const url = this.$window.URL.createObjectURL(data);
          const a = angular.element(`
          <a
            style="display: none"
            href="${url}"
            download="${file}">
          </a>`)[0];

          angular.element('body')[0].append(a);
          a.click();

          this.$window.URL.revokeObjectURL(url);
        });
    } else {
      this.$window.location.assign(link);
    }
  }

  // Source: https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
  hashString(string) {
    let hash = 0;
    let i;
    let chr;

    if (string.length === 0) return hash;

    for (i = 0; i < string.length; i++) {
      chr = string.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }

    return hash;
  }

  // some_words --> Some Words
  snakeCaseToTitleCase(string) {
    return (string || '')
      .split('_')
      .reduce((arr, fragment) => {
        arr.push(fragment.charAt(0).toUpperCase() + fragment.slice(1));
        return arr;
      }, [])
      .join(' ');
  }

  returnObjectByColumn(array, column, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][column] === value) {
        return array[i];
      }
    }

    return null;
  }

  calculatePercentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  /** @deprecated: this function has migrated to formatDate in utils/date.ts */
  mediumDateFormat(dateString, userLocale) {
    const d = dayjs.utc(dateString).isUTC() ? dateString : dayjs.utc(dateString);

    if (dayjs(d).isValid()) {
      return dayjs(d).locale(userLocale).format('D MMM YYYY');
    }

    return null;
  }

  sanitizedSlug(name) {
    return name.replace(/-|\s|\d/g, '').toLowerCase();
  }

  /**
   * @param {any[]} array
   * The array of object that need to converted into { isDisplay: boolean, display: string, options: array[] }
   * @param {string} key
   * The arrtribute value that use for grouping the array @param and will be on the display value as well
   * @param {object} extraObject
   * An object value that need to be injected into the return value
   * @return { isDisplay: boolean, display: string, options: array[] }[]
   *
   * Sample Input
   * [
   *  {
   *    id: string,
   *    status_message: string,
   *    group: string
   *  }
   * ]
   *
   * Sample Output
   * [
   *  {
   *    ...extraObject,
   *    display,
   *    options: [
   *      ...extraObject,
   *      @param input
   *    ]
   *  }
   * ]
   */
  groupArrayByKey(array, key, extraObject = {}) {
    const map = {};
    array.forEach((e) => {
      const k = e[key];
      map[k] = map[k] || [];
      map[k].push({
        ...extraObject,
        ...e,
      });
    });
    return Object.keys(map).map((k) => {
      return { ...extraObject, display: k, options: map[k] };
    });
  }

  isToday(date) {
    return moment().diff(moment(date), 'days') === 0;
  }

  isYesterday(date) {
    return moment().diff(moment(date), 'days') === 1;
  }

  /**
   * @param {url} string
   * The array of object that need to converted into { isDisplay: boolean, display: string, options: array[] }
   * An object value that need to be injected into the return value
   * @return { param1: value, param2: value }
   *
   */
  convertQueryParamsToJson(url) {
    const result = {};
    url.split('&').forEach((part) => {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  // Converts the String "true" to the Boolean true
  convertBooleanAsStringToBoolean(booleanAsString) {
    return JSON.parse(booleanAsString);
  }

  /**
   *
   * @param {object} data
   * @param {['param1', 'param2', 'param3', '...']} allowed
   * Filter unnecessary key from whitelist array
   * @returns {{ param1: value, param2: value, param3: value }}
   */
  filterObjectByKeys(data, allowed) {
    return Object.keys(data)
      .filter((key) => allowed.includes(key))
      .reduce((res, key) => {
        const obj = res;
        obj[key] = data[key];
        return obj;
      }, {});
  }

  // noopener prevents tabjacking, noreferrer adds to privacy
  openNewTab(url, useNoReferrer) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: url,
      rel: useNoReferrer ? 'noopener noreferrer' : 'noopener',
    }).click();
  }

  convertArrayStringToArrayNumber(arrayString) {
    return arrayString
      .join(',')
      .split(',')
      .map((id) => {
        if (Number.isNaN(Number(id))) {
          return id;
        }
        return Number(id);
      });
  }

  pluralize(amount, singular, plural) {
    return amount === 1 ? singular : plural;
  }

  isObjectEmpty(object) {
    return !Object.keys(object).length;
  }

  formatHsCodeNumber(hsCodeNumber) {
    return formatHsCodeNumber(hsCodeNumber);
  }

  highlightInputTerm(textWhereToSearch, termToSearch, htmlTag) {
    const regExToSearch = new RegExp(termToSearch, 'gi');
    const found = textWhereToSearch.search(regExToSearch) !== -1;
    return !found
      ? textWhereToSearch
      : textWhereToSearch.replace(regExToSearch, `<${htmlTag}>$&</${htmlTag}>`);
  }
}

export { HelperService };
