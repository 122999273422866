import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SPEED_DIALS_MIXPANEL_EVENTS_NAMES } from '@client/src/global/components/shipment-action/speedDialsMixpanelEvents';
import { IShipmentListItem } from '@typings/shipment';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import { useDropdownButtonContext } from '@client/core/components/react/DropdownButton/context';
import { baseStyleIcon, baseStyleIconBox } from '../styles';

interface ContactSupportMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
}

export default function ContactSupportMenuItem({
  divider = false,
  shipmentListItem,
}: ContactSupportMenuItemProps) {
  const { closeDropDown } = useDropdownButtonContext();

  const onClickHandler = () => {
    window.zE?.('messenger', 'open');
    MixpanelService.track(
      SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromManageShipmentPageDropdown.contactSupport,
      {
        easyship_shipment_id: shipmentListItem.easyship_shipment_id,
      }
    );
    closeDropDown?.();
  };

  return (
    <MenuItem onClick={onClickHandler} divider={divider}>
      <div style={baseStyleIconBox}>
        <i className="icon-person-clock text-xl" style={{ color: baseStyleIcon.colorGrey }} />
      </div>
      <span className="text-base">
        <FormattedMessage id="shipments.speed-dial.contact-support" />
      </span>
    </MenuItem>
  );
}
