import ReactRootProviders from '@app/contexts/providers/ReactRootProviders';
import { BatteryOptionsProps } from '@client/src/create-shipments/shipment-details-drawer/sections/order-section/order-form/types';
import React, { lazy, ReactElement, Suspense } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { react2angular } from 'react2angular';

const BatteryOptions = lazy(() =>
  import(
    '@client/src/create-shipments/shipment-details-drawer/sections/order-section/order-form/FedexOptions'
  ).then((module) => ({
    default: module.BatteryOptions,
  }))
);

export function WrappedBatteryOptions(props: BatteryOptionsProps): ReactElement {
  const methods = useForm({ mode: 'all' });

  return (
    <ReactRootProviders>
      <FormProvider {...methods}>
        <Suspense fallback={null}>
          <BatteryOptions {...props} />
        </Suspense>
      </FormProvider>
    </ReactRootProviders>
  );
}

export const AngularBatteryOptions = react2angular(WrappedBatteryOptions, [
  'fieldName',
  'onChange',
]);
