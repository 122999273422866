import { ValueOf } from 'type-fest';

export const COUNTRY_ID = {
  Australia: 14,
  Canada: 39,
  HongKong: 96,
  Singapore: 199,
  UnitedStates: 234,
  UnitedKingdom: 78,
} as const;

export const COUNTRY_ALPHA2 = {
  Australia: 'AU',
  Canada: 'CA',
  HongKong: 'HK',
  Singapore: 'SG',
  UnitedStates: 'US',
  UnitedKingdom: 'GB',
  Netherlands: 'NL',
  Germany: 'DE',
} as const;

export const COUNTRY_NAME = {
  Australia: 'Australia',
  Canada: 'Canada',
  HongKong: 'Hong Kong',
  Singapore: 'Singapore',
  UnitedStates: 'United States',
  UnitedKingdom: 'United Kingdom',
} as const;

export interface ICountry {
  id: ValueOf<typeof COUNTRY_ID>;
  name: ValueOf<typeof COUNTRY_NAME>;
  alpha2: ValueOf<typeof COUNTRY_ALPHA2>;
}
