import { IComponentController, IOnChangesObject } from 'angular';
import { IPaymentService } from 'typings/payment-service';
import { ISubscriptionObject, IShowChangePlanObject } from 'typings/subscription';
import { ICompany, IUserSession } from 'typings/user-session';
import { PaymentFormId } from '@client/data/payment-form-id';
import {
  BillingInterval,
  DefinedSubscriptionPlanId,
  FlexibleSubscriptionPlanId,
} from '@client/data/subscription';

import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import template from './change-plan-integration.html?raw';
import style from './change-plan-integration.module.scss';

const MIXPANEL_DOWNGRADE_OPEN = 'Subscription - Downgrade - Open';

class ChangePlanIntegration implements IComponentController {
  style = style;
  esComparePlanToggleState?: boolean;
  esShowModal: Partial<IShowChangePlanObject> = {
    compare: false,
    upgrade: false,
    downgrade: false,
    endTrial: false,
    enterpriseCall: false,
    callScheduledCard: false,
    cancel: false,
    churnReasonsDialog: false,
    enjoyFreeTrial: false,
  };
  choosePlanId: number;
  esSelectedPlanId?: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId;
  _company: ICompany;

  static $inject = [
    '$state',
    'SubscriptionService',
    'UserSession',
    'PaymentService',
    'MixpanelService',
    '$scope',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private SubscriptionService: SubscriptionService,
    private UserSession: IUserSession,
    private PaymentService: IPaymentService,
    private MixpanelService: MixpanelService,
    private $scope: ng.IScope
  ) {
    this._company = this.UserSession.company;
    this.choosePlanId = DefinedSubscriptionPlanId.Plus;
    this.esSelectedPlanId = DefinedSubscriptionPlanId.Plus;
    this.toggleChurnReasonsDialog = this.toggleChurnReasonsDialog.bind(this);
    this.selectPlanAction = this.selectPlanAction.bind(this);
    this.closeComparePlanModal = this.closeComparePlanModal.bind(this);
    this.handleComparePlanToggleState = this.handleComparePlanToggleState.bind(this);
  }

  $onInit(): void {
    const { currentSubscription } = this.SubscriptionService;
    this.esComparePlanToggleState =
      this.esComparePlanToggleState ?? currentSubscription?.plan?.interval === BillingInterval.Year;

    if (!this.esShowModal) {
      throw new Error('Change Plan Integration: An es Show Modal binding must be provided.');
    }

    this.resetSelectedPlanId();

    if (!this.SubscriptionService.plansDetail) {
      this.SubscriptionService.fetchPlansDetail({
        country_id: this._company.country_id,
      });
    }

    if (!this.SubscriptionService.currentSubscription) {
      this.SubscriptionService.fetchCurrentSubscription({
        company_id: this._company.id,
      });
    }
  }

  $onChanges(changes: IOnChangesObject | null): void {
    if (changes) {
      const selectedId = changes.esSelectedPlanId;
      if (selectedId && selectedId.currentValue !== selectedId.previousValue) {
        this.resetSelectedPlanId();
      }
    }
  }

  esCloseCompareCallback(): void {
    // esCloseCompareCallback expression bindings, need to add this in order for typescript to successfully compile
  }

  esCloseUpgradeCallback(): void {
    // esCloseUpgradeCallback expression bindings, need to add this in order for typescript to successfully compile
  }

  esCloseDowngradeCallback(): void {
    // esCloseDowngradeCallback expression bindings, need to add this in order for typescript to successfully compile
  }

  esCloseModalCallback(): void {
    // esCloseModalCallback expression bindings, need to add this in order for typescript to successfully compile
  }

  esSuccessUpgradePlanAction(): void {
    // esSuccessUpgradePlanAction expression bindings, need to add this in order for typescript to successfully compile
  }

  esSuccessDowngradePlanAction(): void {
    // esSuccessDowngradePlanAction expression bindings, need to add this in order for typescript to successfully compile
  }

  esSeeMorePlansAction(): void {
    // esSeeMorePlansAction expression bindings, need to add this in order for typescript to successfully compile
  }

  get freePlanId(): DefinedSubscriptionPlanId {
    return DefinedSubscriptionPlanId.Free;
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  resetSelectedPlanId(): void {
    if (this.esSelectedPlanId) {
      this.choosePlanId = this.esSelectedPlanId;
    }
  }

  triggerCloseModalCallbackAndResetSelectedPlanId(): void {
    this.esCloseModalCallback();
    this.resetSelectedPlanId();
  }

  selectPlanAction(selectedPlanId: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId): void {
    this.esShowModal.compare = false;

    const currentSubscriptionPlan = this.currentSubscription?.plan;
    if (!currentSubscriptionPlan) return;

    const selectedPlan = this.SubscriptionService.findPlanByPlanId(selectedPlanId);
    if (!selectedPlan) return;

    this.choosePlanId = selectedPlan.id;

    const isCurrentlySubscribedPlanSelected = selectedPlanId === currentSubscriptionPlan.id;
    const isFreePlanSelected = selectedPlanId === DefinedSubscriptionPlanId.Free;
    const hasHigherRankingThanSubscribed = selectedPlan.ranking > currentSubscriptionPlan.ranking;
    const isEnterprisePlanSelected = selectedPlanId === FlexibleSubscriptionPlanId.Enterprise;

    if (isEnterprisePlanSelected) {
      this.UserSession.openSalesMeetingLink();
      this.$state.go(this.$state.current, { open: null });
      this.resetSelectedPlanId();
      this.MixpanelService.track('Subscription - Enterprise - Open');
      this.$scope.$apply();
      return;
    }

    if (isCurrentlySubscribedPlanSelected) {
      this.esShowModal.upgrade = true;

      if (
        (this.esComparePlanToggleState &&
          currentSubscriptionPlan.interval === BillingInterval.Month) ||
        (this.esComparePlanToggleState === false &&
          currentSubscriptionPlan.interval === BillingInterval.Year)
      ) {
        this.mixpanelSelect(this.choosePlanId, 'period switch');
      }

      this.$scope.$apply();
      return;
    }

    const isOnTrialMode = this.SubscriptionService.currentSubscriptionPeriod === 'TRIAL';

    if (isOnTrialMode && isFreePlanSelected) {
      // For trial user, if the selected plan is Free,
      // the user is ending the free trial.
      this.esShowModal.enjoyFreeTrial = true;
    } else if (isOnTrialMode || hasHigherRankingThanSubscribed) {
      // For trial user, if the selected plan is any plan except Free.
      // Note that the upgrade modal can display either Upgrade or Try for Free
      // depending on the account's current subscription.
      this.esShowModal.upgrade = true;
    } else {
      this.esShowModal.downgrade = true;
    }

    if (this.esShowModal.upgrade) {
      this.mixpanelSelect(this.choosePlanId, 'upgrade');
    } else {
      this.mixpanelSelect(this.choosePlanId, 'downgrade');
      this.MixpanelService.track(MIXPANEL_DOWNGRADE_OPEN);
    }

    this.$scope.$apply();
  }

  handleComparePlanToggleState(value: BillingInterval): void {
    // if value is year, then compare plan toggle state is true

    this.esComparePlanToggleState = value === BillingInterval.Year;
    this.$scope.$apply();
  }

  mixpanelSelect(planId: number, eventName: string): void {
    this.MixpanelService.track('Subscription - Plans - Select Plan', {
      reason: `plan id: ${planId}, ${eventName}`,
    });
  }

  closeUpgradePlanModal(): void {
    this.PaymentService.unregisterPaymentForm(PaymentFormId.UpgradePlan);
    this.esShowModal.upgrade = false;
    this.$state.go(this.$state.current, { open: null });
    this.esCloseUpgradeCallback();
    this.triggerCloseModalCallbackAndResetSelectedPlanId();

    this.MixpanelService.track('Subscription - Payment - Close');
  }

  closeDowngradePlanModal(): void {
    this.PaymentService.unregisterPaymentForm(PaymentFormId.DowngradePlan);
    this.esShowModal.downgrade = false;
    this.$state.go(this.$state.current, { open: null });
    this.esCloseDowngradeCallback();
    this.triggerCloseModalCallbackAndResetSelectedPlanId();

    this.MixpanelService.track('Subscription - Downgrade - Close');
  }

  closeCancelPlanModal(): void {
    this.PaymentService.unregisterPaymentForm(PaymentFormId.DowngradePlan);
    this.esShowModal.cancel = false;
    this.esShowModal.endTrial = false;
    this.$state.go(this.$state.current, { open: null });
    this.triggerCloseModalCallbackAndResetSelectedPlanId();

    this.MixpanelService.track('Subscription - Cancel - Close');
  }

  seeMorePlansAction(origin?: string): void {
    if (origin && ['upgrade', 'downgrade'].includes(origin)) {
      const formName =
        origin === 'upgrade' ? PaymentFormId.UpgradePlan : PaymentFormId.DowngradePlan;

      this.PaymentService.unregisterPaymentForm(formName);
    }

    this.esShowModal.upgrade = false;
    this.esShowModal.downgrade = false;
    this.esShowModal.compare = true;
    this.esShowModal.callScheduledCard = false;
    this.esShowModal.cancel = false;
    this.esShowModal.endTrial = false;

    if (origin) {
      switch (origin) {
        case 'upgrade':
          this.MixpanelService.track('Subscription - Payment - See More Plans');
          break;
        case 'downgrade':
          this.MixpanelService.track('Subscription - Downgrade - See More Plans');
          break;
        case 'enterprise':
          this.MixpanelService.track('Subscription - Enterprise - Back');
          break;
        default:
          break;
      }
    }

    this.MixpanelService.track('Subscription - Plans - Open');
    this.esSeeMorePlansAction();
  }

  successUpgradePlan(): void {
    this.PaymentService.unregisterPaymentForm(PaymentFormId.UpgradePlan);
    this.esShowModal.upgrade = false;
    this.esSuccessUpgradePlanAction();
  }

  trackEventFromEndOfTrialToFreePlan() {
    this.SubscriptionService.showTrialEndModal &&
      this.choosePlanId === this.freePlanId &&
      this.MixpanelService.track('Subscription - Trial Expired - Move To Free');
  }

  successDowngradePlan(): void {
    this.trackEventFromEndOfTrialToFreePlan();
    this.PaymentService.unregisterPaymentForm(PaymentFormId.DowngradePlan);
    this.esSuccessDowngradePlanAction();
  }

  successCancelPlan(): void {
    this.PaymentService.unregisterPaymentForm(PaymentFormId.DowngradePlan);
    this.esSuccessDowngradePlanAction();
  }

  closeComparePlanModal(): void {
    this.esShowModal.compare = false;
    this.$state.go(this.$state.current, { open: null });
    this.esCloseCompareCallback();
    this.triggerCloseModalCallbackAndResetSelectedPlanId();

    this.MixpanelService.track('Subscription - Plans - Close');
  }

  successScheduleCall(): void {
    this.esShowModal.callScheduledCard = true;
  }

  closeCallScheduledModal(): void {
    this.esShowModal.callScheduledCard = false;
    this.triggerCloseModalCallbackAndResetSelectedPlanId();
  }

  toggleChurnReasonsDialog(): void {
    this.esShowModal.cancel = false;
    this.esShowModal.downgrade = false;
    this.esShowModal.endTrial = false;
    this.esShowModal.churnReasonsDialog = !this.esShowModal.churnReasonsDialog;

    this.$scope.$apply();
  }

  closeEnjoyFreeTrialModal() {
    this.esShowModal.enjoyFreeTrial = false;
    this.$state.go(this.$state.current, { open: null });
  }

  closeEnterpriseCallModal() {
    this.esShowModal.enterpriseCall = false;
  }

  proceedToEndFreeTrial() {
    this.esShowModal.enjoyFreeTrial = false;
    this.esShowModal.endTrial = true;
  }
}

const ChangePlanIntegrationComponent: ng.IComponentOptions = {
  controller: ChangePlanIntegration,
  template,
  bindings: {
    esShowModal: '=?',
    esSelectedPlanId: '<',
    esCloseCompareCallback: '&',
    esCloseUpgradeCallback: '&',
    esCloseDowngradeCallback: '&',
    esCloseModalCallback: '&',
    esComparePlanToggleState: '=?',
    esSuccessUpgradePlanAction: '&',
    esSuccessDowngradePlanAction: '&',
    esSeeMorePlansAction: '&',
    esTriggerSource: '<',
  },
};

export { ChangePlanIntegrationComponent };
