import { toastError } from '@app/components/Toastify';
import { useGetAddressesQuery } from '@app/pages/address/hooks/queries/getAddressesQuery';
import useRegisterCourierSubAccountsMutation from '@app/pages/company/hooks/mutations/useRegisterCourierSubAccountsMutation';
import { RegisterCourierSubAccountPayload } from '@app/pages/company/types';
import { UpsDapModalProps } from '@app/pages/couriers/components/UpsDapModal/types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Button, Checkbox, IconButton } from 'easyship-components';
import { CrossLarge } from 'easyship-components/icons';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import gbUpsDapRate from '@assets/images/dashboard/courier/ups-dap/ups-rate-GB.svg';
import usUpsDapRate from 'assets/images/dashboard/courier/ups-dap/ups-rate-US.svg';
import defaultUpsDapRate from '@assets/images/dashboard/courier/ups-dap/ups-rate-default.svg';
import noPercentageUpsDapRate from '@assets/images/dashboard/courier/ups-dap/ups-rate-no-percentage.svg';
import Cookies from 'universal-cookie';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// NOTE: not going to import from constants.js in /update-courier because of linter error and the file could not be found
// tac: Terms & Conditions
// ptac: Paperless Terms and Conditions
// ta: Technology Agreement
// pi: Prohibited Items
// hod: Hazardous materials or Dangerous goods
const linkMap = {
  DE: {
    tac: 'https://www.ups.com/de/en/support/shipping-support/legal-terms-conditions.page',
    hod: 'https://www.ups.com/de/en/support/shipping-support/shipping-special-care-regulated-items/hazardous-materials-guide.page',
    pi: 'https://www.ups.com/de/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
  },
  GB: {
    tac: 'https://www.ups.com/assets/resources/webcontent/en_GB/terms_carriage_eur.pdf',
    ta: 'https://www.ups.com/assets/resources/webcontent/en_GB/UTA.pdf',
    pi: 'https://www.ups.com/gb/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
    ptac: 'https://www.ups.com/assets/resources/webcontent/en_GB/DAP-paperless-invoice-terms-conditions.pdf',
  },
  US: {
    tac: 'https://assets.easyship.com/app/courier-docs/ups-access-license-agreement.pdf',
    ta: 'https://www.ups.com/assets/resources/media/en_US/D15_US.pdf',
    pi: 'https://www.ups.com/us/en/help-center/shipping-support/prohibited-items.page',
  },
  CA: {
    tac: 'https://www.ups.com/assets/resources/webcontent/en_CA/terms_service_ca.pdf',
    ta: 'https://www.ups.com/assets/resources/webcontent/en_CA/UTA.pdf',
    pi: 'https://www.ups.com/ca/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
  },
  NL: {
    tac: 'https://www.ups.com/assets/resources/webcontent/en_GB/terms_carriage_nl.pdf',
    ta: 'https://www.ups.com/assets/resources/webcontent/en_GB/UTA.pdf',
    pi: 'https://www.ups.com/nl/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
    ptac: 'https://www.ups.com/assets/resources/webcontent/en_GB/DAP-paperless-invoice-terms-conditions.pdf',
  },
};

function UpsDapModal({
  isOpen,
  onClose,
  countries,
  addressId,
  onRegisterSuccess,
}: UpsDapModalProps) {
  const { formatMessage } = useIntl();

  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  // to handle more than 1 UPS DAP supported countries supported in checkout
  const [dapCountriesStep, setDapCountriesStep] = useState(countries);

  const { data: addresses, refetch: getAddresses } = useGetAddressesQuery({ enabled: false });

  const {
    mutateAsync: registerCourierSubAccountMutateAsync,
    isLoading,
    isSuccess,
    reset: resetRegisterCourierSubAccountsMutationState,
  } = useRegisterCourierSubAccountsMutation();

  // setting new countries every modal open
  useEffect(() => {
    setDapCountriesStep(countries);
  }, [countries]);

  // fetch addresses if don't have the addressId (to cover new UPS DAP modal logic)
  useEffect(() => {
    if (!addressId && isOpen) {
      getAddresses();
    }
  }, [addressId, getAddresses, isOpen]);

  const activeDapCountry = dapCountriesStep[0];

  const modalTitle = () => {
    return activeDapCountry ? `ups-dap-modal.title-${activeDapCountry}` : 'ups-dap-modal.title';
  };

  const specialRateImage = () => {
    switch (activeDapCountry) {
      case 'GB':
        return gbUpsDapRate;
      case 'US':
        return usUpsDapRate;
      case 'NL':
        return noPercentageUpsDapRate;
      case 'DE':
        return noPercentageUpsDapRate;
      default:
        return defaultUpsDapRate;
    }
  };

  const modalDescription = () => {
    return activeDapCountry
      ? `ups-dap-modal.description-${activeDapCountry}`
      : 'ups-dap-modal.description';
  };

  const agreementCheckboxLabel = () => {
    return activeDapCountry && activeDapCountry !== 'US'
      ? `ups-dap-modal.agreements-${activeDapCountry}`
      : 'ups-dap-modal.agreements';
  };

  const successMessages = () => {
    return activeDapCountry ? `ups-dap-modal.success-${activeDapCountry}` : 'ups-dap-modal.success';
  };

  const agreementCheckboxLinks = () => {
    let links;
    const defaultLinks = {
      'tac-link': (chunk: string) => guardLinkTag(linkMap.US.tac, chunk),
      'ta-link': (chunk: string) => guardLinkTag(linkMap.US.ta, chunk),
      'pi-link': (chunk: string) => guardLinkTag(linkMap.US.pi, chunk),
    };

    switch (activeDapCountry) {
      case 'GB':
        links = {
          'tac-link': (chunk: string) => guardLinkTag(linkMap.GB.tac, chunk),
          'ta-link': (chunk: string) => guardLinkTag(linkMap.GB.ta, chunk),
          'pi-link': (chunk: string) => guardLinkTag(linkMap.GB.pi, chunk),
          'ptac-link': (chunk: string) => guardLinkTag(linkMap.GB.ptac, chunk),
        };
        break;
      case 'DE':
        links = {
          'tac-link': (chunk: string) => guardLinkTag(linkMap.DE.tac, chunk),
          'hod-link': (chunk: string) => guardLinkTag(linkMap.DE.hod, chunk),
          'pi-link': (chunk: string) => guardLinkTag(linkMap.DE.pi, chunk),
        };
        break;
      case 'CA':
        links = {
          'tac-link': (chunk: string) => guardLinkTag(linkMap.CA.tac, chunk),
          'ta-link': (chunk: string) => guardLinkTag(linkMap.CA.ta, chunk),
          'pi-link': (chunk: string) => guardLinkTag(linkMap.CA.pi, chunk),
        };
        break;
      case 'NL':
        links = {
          'tac-link': (chunk: string) => guardLinkTag(linkMap.NL.tac, chunk),
          'ta-link': (chunk: string) => guardLinkTag(linkMap.NL.ta, chunk),
          'pi-link': (chunk: string) => guardLinkTag(linkMap.NL.pi, chunk),
          'ptac-link': (chunk: string) => guardLinkTag(linkMap.NL.ptac, chunk),
        };
        break;
      case 'US':
      default:
        links = defaultLinks;
        break;
    }
    return links;
  };

  function guardLinkTag(link: string, chunk: string) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {chunk}
      </a>
    );
  }

  function handleToggleCheckAgreement(): void {
    setIsAgreementChecked((state) => !state);
  }

  function handleAgreeClicked(): void {
    let originAddress = '';
    if (addressId) {
      originAddress = addressId;
    } else {
      // extra logic for finding the address to create sub account if there is no addressId passed
      const findAddressByCountry = addresses?.shippingAddresses.find(
        (address) => address.country.alpha2 === activeDapCountry && address.is_active === true
      );
      originAddress = findAddressByCountry ? findAddressByCountry.id : '';
    }

    const payload: RegisterCourierSubAccountPayload = {
      umbrellaName: 'UPS',
      originAddressId: originAddress,
    };

    registerCourierSubAccountMutateAsync({ payload }).catch(() => {
      toastError(formatMessage({ id: 'toast.default-error' }));
    });
  }

  function handleCloseModal(): void {
    handleRedirect();
    if (isSuccess) {
      onRegisterSuccess();
      // resetting all the states coming from mutation for the next modal open.
      resetRegisterCourierSubAccountsMutationState();
      handleToggleCheckAgreement();
    } else {
      onClose();
    }
  }

  // If there is a redirect url, it means that the user has been redirected from a partner app
  // and they should be taken back to that url once the modal is closed
  function handleRedirect(): void {
    const cookies = new Cookies();
    const url = cookies.get('redirectBackUrl');

    if (url) {
      const status = `ups_dap_status=${isSuccess ? 'accepted' : 'cancelled'}`;
      const redirectUrl = url.includes('?') ? `${url}&${status}` : `${url}?${status}`;
      cookies.remove('redirectBackUrl');
      window.open(redirectUrl, '_self');
    }
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      TransitionProps={{ timeout: 400 }}
      maxWidth="sm"
      fullWidth
      open={isOpen}
    >
      <div className="bg-white rounded-md">
        {/* Header */}
        <div className="flex items-center justify-between w-full py-5 pl-12 pr-5 text-xl border-b border-sky-300">
          <FormattedMessage id={modalTitle()} />
          <IconButton
            flat
            tooltipProps={{
              label: 'Close',
              usePortal: true,
              autoPlacement: ['top'],
            }}
            className=" p-0 top-[4%] right-[4%]"
            onClick={handleCloseModal}
            aria-label="Close"
          >
            <CrossLarge />
          </IconButton>
        </div>
        {/* End of Header */}
        {/* Body */}
        <div className="flex flex-col items-center w-full px-12 py-10">
          <img src={specialRateImage()} alt="Special Rate" className="mb-5" />
          {!isSuccess ? (
            <>
              <FormattedMessage id={modalDescription()} />
              <div className="max-w-full my-3">
                <Checkbox
                  className="!m-0 min-w-8"
                  onChange={handleToggleCheckAgreement}
                  label={
                    <span className="ml-3">
                      <FormattedMessage
                        id={agreementCheckboxLabel()}
                        values={agreementCheckboxLinks()}
                      />
                    </span>
                  }
                />
              </div>
              <p className="mb-5 text-sm">
                <FormattedMessage id="global.ups-trademark" />
              </p>
              <Button
                disabled={isLoading || !isAgreementChecked}
                loading={isLoading}
                color="primary"
                onClick={handleAgreeClicked}
              >
                <FormattedMessage id="global.i-accept" />
              </Button>
            </>
          ) : (
            <>
              <div className="mb-4">
                <FormattedMessage id="shipments.fix-monkey.headers.success" />
              </div>
              <div className="mb-5">
                <FormattedMessage id={successMessages()} />
              </div>

              <Button color="primary" onClick={handleCloseModal}>
                <FormattedMessage id="global.close" />
              </Button>
            </>
          )}
        </div>
        {/* End of Body */}
      </div>
    </Dialog>
  );
}

export default UpsDapModal;
