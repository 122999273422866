(function () {
  MetadataService.$inject = ['$rootScope'];
  function MetadataService($rootScope) {
    const service = this;

    service.meta = {};

    // Meta tag configurations for different contexts
    const META_TAG_CONFIGS = {
      login: {
        'meta[property="og:title"]': 'Easyship Login | Smart Shipping Dashboard',
        'meta[name="description"]':
          'Sign-in to Easyship, the easiest way to ship worldwide. Start free or try our professional plans free for 14 days to access our most advanced features.',
        'meta[property="og:description"]':
          'Sign-in to Easyship, the easiest way to ship worldwide. Start free or try our professional plans free for 14 days to access our most advanced features.',
      },
      dashboard: {
        'meta[property="og:title"]': 'Easyship eCommerce Shipping | Now You Can Sell Worldwide',
        'meta[name="description"]':
          'Ship with more than 550+ courier partners globally, compare rates and save up to 91% off retail prices. Get started for free.',
        'meta[property="og:description"]':
          'Ship with more than 550+ courier partners globally, compare rates and save up to 91% off retail prices. Get started for free.',
      },
    };

    function updateMetaTags(metaTags) {
      Object.entries(metaTags).forEach(([selector, content]) => {
        const metaElement = document.querySelector(selector);
        if (metaElement) {
          metaElement.setAttribute('content', content);
        }
      });
    }

    service.loadMetadata = function (metadata) {
      if (metadata) {
        service.meta.title = document.title = metadata.title || 'Easyship | Ship outside the box';
        service.meta.description =
          metadata.description ||
          'The easiest way to ship worldwide. Ship with more than 250+ couriers, saving up to 70%.';
        service.meta.ogImage1 = metadata.ogImage1 || 'https://www.easyship.com/share_image_1.png';
        service.meta.ogWidth = metadata.ogWidth || '1024';
        service.meta.ogHeight = metadata.ogHeight || '512';
        // service.meta.url = metadata.url || $location.absUrl();
        // service.meta.image = metadata.image || 'fallbackimage.jpg';
        // service.meta.ogpType = metadata.ogpType || 'website';
        // service.meta.twitterCard = metadata.twitterCard || 'summary_large_image';
        // service.meta.twitterSite = metadata.twitterSite || '@fallback_handle';

        updateMetaTags(META_TAG_CONFIGS.login);
      }
    };

    service.setMetadata = function (metadata) {
      service.meta.title = document.title = metadata.title || 'Easyship | Ship outside the box';
      service.meta.description =
        metadata.description ||
        'The easiest way to ship worldwide. Ship with more than 250+ couriers, saving up to 70%.';
      service.meta.ogImage1 = metadata.ogImage1 || 'https://www.easyship.com/share_image_1.png';
      service.meta.ogWidth = metadata.ogWidth || '1024';
      service.meta.ogHeight = metadata.ogHeight || '512';

      updateMetaTags(META_TAG_CONFIGS.dashboard);
    };

    // Route change handler => Sets the metadata defined in the route
    // event, toState, toParams, fromState, fromParams
    $rootScope.$on('$stateChangeSuccess', function (event, toState) {
      service.loadMetadata(toState.metadata);
    });
  }

  angular.module('app.service.MetadataService', []).service('MetadataService', MetadataService);
})();
