export const SPEED_DIALS_MIXPANEL_EVENTS_NAMES = {
  fromManageShipmentPageDropdown: {
    rescheduleHandover: 'Speed Dials - Reschedule handover',
    editShipment: 'Speed Dials - Edit shipment',
    claimInsurance: 'Speed Dials - Claim insurance',
    markAsDelivered: 'Speed Dials - Mark As Delivered',
    contactSupport: 'Contact Support - Label Rejected',
  },
  fromShipmentDetailsPagePanel: {
    printShippingDocument: 'View Details - Speed Dials - Print Shipping document',
    printCommercialInvoice: 'View Details - Speed Dials - Print Commercial invoice',
    duplicateShipment: 'View Details - Speed Dials - Duplicate shipment',
    rescheduleHandover: 'View Details - Speed Dials - Reschedule handover',
    editShipment: 'View Details - Speed Dials - Edit shipment',
    claimInsurance: 'View Details - Speed Dials - Claim insurance',
    markAsDelivered: 'View Details - Speed Dials - Mark As Delivered',
    createReturnLabel: 'View Details - Speed Dials - Create Return label',
    cancel: 'View Details - Speed Dials - Cancel',
  },
};
