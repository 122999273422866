import angular from 'angular';

import { AngularManageTeamListComponent } from '@client/core/components/wrappers/account/Team/AngularManageTeamListComponent';
import { TeamComponent } from './team.component';

const MODULE_NAME = 'app.account.team';

angular
  .module(MODULE_NAME, [])
  .component('team', TeamComponent)
  .component('esAccountManageTeamList', AngularManageTeamListComponent);

export default MODULE_NAME;
