import React from 'react';

export const DropdownButtonContext = React.createContext<{
  closeDropDown: () => void;
} | null>(null);

export const useDropdownButtonContext = () => {
  const context = React.useContext(DropdownButtonContext);
  if (context === null) {
    throw new Error('useDropdownButtonContext must be used within a DropdownButtonProvider');
  }
  return context;
};
