import angular from 'angular';
import React, { useContext, useMemo } from 'react';
import { IPlansDetail, ISubscriptionObject } from 'typings/subscription';
import { SubscriptionService } from '../services/subscription/subscription.service';

interface ISubscriptionServiceContext {
  plansDetail: IPlansDetail | null;
  currentSubscription: ISubscriptionObject | null;
  isPlanBadgeVisible: SubscriptionService['isPlanBadgeVisible'];
  getPlanNameByFeatureKey: SubscriptionService['getPlanNameByFeatureKey'];
  currentSubscriptionPeriod: SubscriptionService['currentSubscriptionPeriod'];
  isCurrentLegacyPlan: SubscriptionService['isCurrentLegacyPlan'];
  isCurrentPlanChangeable: SubscriptionService['isCurrentPlanChangeable'];
  isLegacyTrialEnd: SubscriptionService['isLegacyTrialEnd'];
  isFeatureAccessible: SubscriptionService['isFeatureAccessible'];
  isMaxCourier: SubscriptionService['isMaxCourier'];
  isPlanVersionOutdated: SubscriptionService['isPlanVersionOutdated'];
  formattedMigrateDate: SubscriptionService['formattedMigrateDate'];
}

const SubscriptionServiceContext = React.createContext<ISubscriptionServiceContext | undefined>(
  undefined
);

interface ISubscriptionServiceProviderProps {
  children: React.ReactNode;
}
function SubscriptionServiceProvider({ children }: ISubscriptionServiceProviderProps) {
  const AngularSubscriptionService = angular
    .element(document.body)
    .injector()
    .get<SubscriptionService>('SubscriptionService');

  const {
    plansDetail,
    currentSubscription,
    isPlanBadgeVisible,
    getPlanNameByFeatureKey,
    currentSubscriptionPeriod,
    isCurrentLegacyPlan,
    isCurrentPlanChangeable,
    isLegacyTrialEnd,
    isFeatureAccessible,
    isMaxCourier,
    isPlanVersionOutdated,
    formattedMigrateDate,
  } = AngularSubscriptionService;

  const value = useMemo<ISubscriptionServiceContext>(
    () => ({
      plansDetail,
      currentSubscription,
      isPlanBadgeVisible,
      getPlanNameByFeatureKey,
      currentSubscriptionPeriod,
      isCurrentLegacyPlan,
      isCurrentPlanChangeable,
      isLegacyTrialEnd,
      isFeatureAccessible,
      isMaxCourier,
      isPlanVersionOutdated,
      formattedMigrateDate,
    }),
    [
      plansDetail,
      currentSubscription,
      isPlanBadgeVisible,
      getPlanNameByFeatureKey,
      currentSubscriptionPeriod,
      isCurrentLegacyPlan,
      isCurrentPlanChangeable,
      isLegacyTrialEnd,
      isFeatureAccessible,
      isMaxCourier,
      isPlanVersionOutdated,
      formattedMigrateDate,
    ]
  );

  return (
    <SubscriptionServiceContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={value}
    >
      {children}
    </SubscriptionServiceContext.Provider>
  );
}

function useSubscriptionService() {
  const context = useContext(SubscriptionServiceContext);
  if (!context) {
    throw new Error('useSubscriptionService must be used within a SubscriptionServiceProvider');
  }
  return context;
}

export { SubscriptionServiceProvider, useSubscriptionService };
