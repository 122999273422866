import angular from 'angular';

import '@client/src/global/services/box';

import { AngularBatteryOptions } from '@client/core/components/wrappers/create-shipment/PickAndPack/AngularBatteryOptions';
import { ShipmentInformationComponent } from './shipment-information.component';

angular
  .module('app.create-shipments.basic.shipment-information', [
    'app.global.box',
    'app.service.PlatformService',
    'app.service.ShipmentHelpers',
  ])
  .component('shipmentInformation', ShipmentInformationComponent)
  .component('batteryOptions', AngularBatteryOptions);
