import React from 'react';
import { useIntl } from 'react-intl';
import { MenuList } from '@material-ui/core';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';

import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import DropdownButton from '@client/core/components/react/DropdownButton';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import ContactSupportMenuItem from '@client/src/global/components/shipment-action/menu-items/ContactSuportMenuItem';
import { ShipmentStatus } from '@/constants/shipments';
import ViewDetailsMenuItem from '../menu-items/ViewDetailsMenuItem';
import PrintAllDocsMenuItem from '../menu-items/PrintAllDocsMenuItem';
import PrintCommercialInvoiceMenuItem from '../menu-items/PrintCommercialInvoiceMenuItem';
import DuplicateShipmentMenuItem from '../menu-items/DuplicateShipmentMenuItem';
import RescheduleHandoverMenuItem from '../menu-items/RescheduleHandoverMenuItem';
import EditShipmentMenuItem from '../menu-items/EditShipmentMenuItem';
import InsuranceClaimMenuItem from '../menu-items/InsuranceClaimMenuItem';
import MarkAsDeliveredMenuItem from '../menu-items/MarkAsDeliveredMenuItem';
import ReturnLabelMenuItem from '../menu-items/ReturnLabelMenuItem';
import CancelShipmentMenuItem from '../menu-items/CancelShipmentMenuItem';
import { ShipmentActionProps } from '../types';
import { SPEED_DIALS_MIXPANEL_EVENTS_NAMES } from '../speedDialsMixpanelEvents';
import SchedulePickupMenuItem from '../menu-items/SchedulePickupMenuItem';

export default function ShipmentActionDropdown({
  onViewDetails,
  onCancelShipment,
  onPrintAllLabels,
  onPrintCommercialInvoice,
  onDuplicateShipment,
  onRescheduleHandover,
  onScheduleHandover,
  onCreateReturnLabel,
  onMarkAsDelivered,
  onEditShipment,
  onStartInsuranceClaim,
  markAsDeliveredModalIsOpen,
  markAsDeliveredModalOnClose,
  markAsDeliveredModalOnConfirm,
  shipmentListItem,
}: ShipmentActionProps) {
  const { formatMessage } = useIntl();
  const { user } = useUserSession();
  const showDuplicateActionButton: boolean = user.hasUserRole('create_shipments');

  return (
    <DropdownButton name={formatMessage({ id: 'shipments.speed-dial.actions' })}>
      <MenuList>
        <ViewDetailsMenuItem divider onViewDetails={onViewDetails} />

        <PrintAllDocsMenuItem
          divider
          shipmentListItem={shipmentListItem}
          onPrintAllLabels={onPrintAllLabels}
        />

        <PrintCommercialInvoiceMenuItem
          divider
          shipmentListItem={shipmentListItem}
          onPrintCommercialInvoice={onPrintCommercialInvoice}
        />
        {showDuplicateActionButton && (
          <DuplicateShipmentMenuItem
            divider
            shipmentListItem={shipmentListItem}
            onDuplicateShipment={onDuplicateShipment}
          />
        )}

        <RescheduleHandoverMenuItem
          divider
          shipmentListItem={shipmentListItem}
          onRescheduleHandover={() => {
            onRescheduleHandover();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromManageShipmentPageDropdown.rescheduleHandover,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
        />
        <SchedulePickupMenuItem
          divider
          shipmentListItem={shipmentListItem}
          onScheduleHandover={() => onScheduleHandover()}
        />
        <EditShipmentMenuItem
          divider
          shipmentListItem={shipmentListItem}
          onEditShipment={async () => {
            await onEditShipment();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromManageShipmentPageDropdown.editShipment,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
        />

        <InsuranceClaimMenuItem
          divider
          shipmentListItem={shipmentListItem}
          onStartInsuranceClaim={async () => {
            await onStartInsuranceClaim();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromManageShipmentPageDropdown.claimInsurance,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
        />

        <MarkAsDeliveredMenuItem
          divider
          shipmentListItem={shipmentListItem}
          onMarkAsDelivered={() => {
            onMarkAsDelivered();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromManageShipmentPageDropdown.markAsDelivered,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
          markAsDeliveredModalIsOpen={markAsDeliveredModalIsOpen}
          markAsDeliveredModalOnClose={markAsDeliveredModalOnClose}
          markAsDeliveredModalOnConfirm={markAsDeliveredModalOnConfirm}
        />

        <ReturnLabelMenuItem
          divider
          shipmentListItem={shipmentListItem}
          onCreateReturnLabel={onCreateReturnLabel}
        />
        {shipmentListItem?.last_status_message?.id ===
          ShipmentStatus.LABEL_REJECTED_AWAITING_COURIER && (
          <ContactSupportMenuItem divider shipmentListItem={shipmentListItem} />
        )}
        <CancelShipmentMenuItem
          shipmentListItem={shipmentListItem}
          onCancelShipment={onCancelShipment}
        />
      </MenuList>
    </DropdownButton>
  );
}

export function WrappedShipmentActionDropdown(props: ShipmentActionProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <ShipmentActionDropdown {...props} />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}
