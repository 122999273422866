import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'easyship-components';
import { Exclaim } from 'easyship-components/icons';
import { twMerge } from 'tailwind-merge';
import { toastError } from '@/components/Toastify';
import { openModal } from '@/utils/modals';
import { ICompanyService } from 'typings/company';
import { useCompany } from '@app/hooks/general/useCompany';
import { openVerificationRequiredModal } from './components/VerificationRequiredModal';
import { VerificationRequiredModalProps } from './types';
import { DOCUMENT_LINK, SESSION_STORAGE_KEY, SUPPORT_LINK } from './constants';

const verificationStateMap = {
  requested: 'requested',
  processing: 'processing',
  unverified: 'unverified',
};

export default function VerificationRequiredBanner({ $injector }: VerificationRequiredModalProps) {
  const CompanyService = $injector.get('CompanyService') as ICompanyService;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const StripeService = $injector.get('StripeService') as any;

  const [isStripeInitiated, setIsStripeInitiated] = useState(false);

  const modalRef = useRef<ReturnType<typeof openModal>>();
  const bannerRef = useRef<HTMLDivElement>(null);

  const company = useCompany();
  const { identityVerificationState, underReviewBannerVisible } = company;

  const { formatMessage } = useIntl();

  const isVerificationRequired =
    identityVerificationState &&
    [
      verificationStateMap.requested,
      verificationStateMap.processing,
      verificationStateMap.unverified,
    ].includes(identityVerificationState);

  const isShowBanner = isVerificationRequired || underReviewBannerVisible;

  const isShowButton =
    (identityVerificationState &&
      [verificationStateMap.requested, verificationStateMap.unverified].includes(
        identityVerificationState
      )) ||
    underReviewBannerVisible;

  const handleVerifyClick = async () => {
    // fetch a client secret and use the Stripe instance to start the verification process
    setIsStripeInitiated(true);

    try {
      const session = await CompanyService.createIdentitiyVerificationSession();
      const stripe = await StripeService.getInstance();
      await stripe.verifyIdentity(session.client_secret);

      modalRef.current?.close();
    } catch (err) {
      toastError(err.data?.errors?.join(',') || formatMessage({ id: 'toast.default-error' }));
    } finally {
      setIsStripeInitiated(false);
    }
  };

  const handleCloseModal = () => {
    setIsStripeInitiated(false);
  };

  const handleContactClick = () => {
    window.open(SUPPORT_LINK, '_blank');
  };

  useEffect(() => {
    if (isShowBanner) {
      document.body.classList.add('show-verification-required-banner');

      if (!underReviewBannerVisible) {
        const hasModalShown = window.sessionStorage.getItem(SESSION_STORAGE_KEY);
        if (!hasModalShown) {
          modalRef.current = openVerificationRequiredModal({
            handleVerify: handleVerifyClick,
            handleClose: handleCloseModal,
          });
          window.sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
        }
      }

      setTimeout(() => {
        if (document.body.classList.contains('show-trial-expiry')) {
          bannerRef.current?.classList.add('mt-[50px]');
        }
      }, 1000);
    } else {
      document.body.classList.remove('show-verification-required-banner');
      if (document.body.classList.contains('show-trial-expiry')) {
        bannerRef.current?.classList.remove('mt-[50px]');
      }
    }
  });

  if (!isShowBanner) {
    return null;
  }

  return (
    <div
      ref={bannerRef}
      className={twMerge(
        'fixed top-0 flex items-center w-full z-[26] pt-4 pr-0 pb-4 pl-[210px]',
        isVerificationRequired ? 'bg-red-100' : 'bg-yellow-100'
      )}
    >
      <div className="flex items-center justify-center gap-3">
        <Exclaim
          className={twMerge(
            'w-5 h-5',
            isVerificationRequired ? 'text-red-900' : 'text-yellow-700'
          )}
        />
        <p className="mb-0 text-base text-ink-900">
          <FormattedMessage
            id={
              isVerificationRequired
                ? `identity-verification.required-banner.description.${identityVerificationState}`
                : 'identity-verification.under-review-banner.description'
            }
            values={{
              // eslint-disable-next-line react/no-unstable-nested-components
              a: (chunks) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a target="_blank" rel="noopener noreferrer" href={DOCUMENT_LINK}>
                  {chunks}
                </a>
              ),
            }}
          />
          &nbsp;
          {!underReviewBannerVisible && (
            <a href={DOCUMENT_LINK} target="_blank" rel="noreferrer" aria-label="support document">
              <FormattedMessage id="global.learn-more" />
            </a>
          )}
        </p>
        {isShowButton && (
          <Button
            loading={isStripeInitiated}
            onClick={
              identityVerificationState === verificationStateMap.requested
                ? handleVerifyClick
                : handleContactClick
            }
          >
            <FormattedMessage
              id={
                identityVerificationState === verificationStateMap.requested
                  ? 'identity-verification.required-banner.verify-now'
                  : 'global.contact-support'
              }
            />
          </Button>
        )}
      </div>
    </div>
  );
}
