import angular from 'angular';

import b13aFiling from './b13a-filing';
import billingOptions from './billing-options';

import './item-list';
import './shipment-information';

angular.module('app.create-shipments.basic', [
  b13aFiling,
  billingOptions,
  'app.create-shipments.basic.item-list',
  'app.create-shipments.basic.shipment-information',
]);
