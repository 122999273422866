import { LazyExoticComponent } from 'react';
import { ModalContainerProps } from '@/components/ModalContainer/types';

// eslint-disable-next-line
export type ComponentDataType = any;

type ComponentTypeLazy = Pick<ModalContainerProps, 'isOpen' | 'handleClose'> & ComponentDataType;

export type ComponentType = LazyExoticComponent<(props: ComponentTypeLazy) => React.JSX.Element>;

export type ModalsType = {
  component: ComponentType;
  data?: ComponentDataType;
  guid?: string;
  close: () => void;
  isClosed?: boolean;
};

type Listener = (d: ModalsType[]) => void;

const createModalsStore = () => {
  let modals = [] as ModalsType[];
  const listeners = new Set<Listener>();

  const notifyListeners = () => {
    listeners.forEach((notify) => notify([...modals]));
  };

  /** Removes closed modals and notifies listeners if any were removed */
  const removeClosedModals = () => {
    const newModals = modals.filter((modal) => !modal.isClosed);
    if (newModals.length !== modals.length) {
      modals = newModals;
      notifyListeners();
    }
  };

  return {
    getModals: () => modals,
    removeClosed: removeClosedModals,
    closeLastOpened: () => {
      if (modals.length) {
        modals = modals.slice(0, -1);
        notifyListeners();
      }
    },
    openModal: (
      component: ComponentType,
      data?: ComponentDataType
    ): { close: () => void } | null => {
      removeClosedModals();
      const lastModal = modals[modals.length - 1];
      if (lastModal && component === lastModal.component && lastModal.isClosed) return null;
      const guid = `modal-${Math.random().toString(36)}`;
      const close = () => {
        const modal = modals.find((f) => f.guid === guid);
        if (modal) {
          modal.isClosed = true;
          modals = [...modals];
          notifyListeners();
        }
      };
      modals = [
        ...modals,
        {
          component,
          data,
          guid,
          close,
        },
      ];
      notifyListeners();
      return {
        close,
      };
    },
    subscribe: (listener: Listener) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
  };
};

const store = createModalsStore();

export default store;

export const openModal = (component: ComponentType, data?: ComponentDataType) =>
  store.openModal(component, data);

export const closeLastModal = () => store.closeLastOpened();

export const removeClosedModals = () => store.removeClosed();
