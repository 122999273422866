import { BoxEditData } from '@client/core/corelogic/models/Box';
import {
  CourierServiceRateData,
  TranslationKey,
} from '@client/core/corelogic/models/CourierServiceRate';
import { HandoverOption } from '@client/core/corelogic/models/HandoverOption';
import { CategoryData, ItemData } from '@client/core/corelogic/models/item';
import {
  FedexOptionalService,
  AuPostMyPostOptionalService,
  NoOptionalService,
  OrderEditData,
  UPSOptionalService,
} from '@client/core/corelogic/models/Order';
import { ParcelData } from '@client/core/corelogic/models/parcel';
import { ReceiverAddressEditData } from '@client/core/corelogic/models/receiverAddress';
import { SenderAddressEditData } from '@client/core/corelogic/models/senderAddress';
import {
  ShipmentData,
  ResidentialIdentification,
  UpsServiceData,
  FedexServiceData,
  fedexHomeDeliveryPremiumOptions,
  fedexDeliverySignatureOptions,
  fedexBatteryOptions,
  FedexBatteryOption,
  FedexDeliverySignatureOption,
  FedexHomeDeliveryPremiumOption,
  UPSDeliveryConfirmationOption,
  upsDeliveryConfirmationOptions,
  ShipmentFormData,
  AdditionalServicesOption,
  FedexOption,
  UPSOption,
  fedexOptions,
  upsOptions,
  AuPostMyPostBusinessOptions,
  AuPostMyPostServiceData,
  auPostMyPostBusinessOptions,
} from '@client/core/corelogic/models/Shipment';
import { HsCodeSelection } from '@client/src/global/components/category-hs-code/types';
import { IDraftAddress } from 'typings/address';
import { IRateItem } from 'typings/rates';
import {
  BoxUpdateData,
  CustomBoxUpdateData,
  IdentifiedAsResidential,
  Incoterms,
  IShipmentAddress,
  IShipmentListItem,
  IShipmentOrderData,
  IShipmentSubsetAddresses,
  IShipmentSubsetOrder,
  ItemUpdateAttributes,
  ParcelAttributes,
  ParcelDeleteAttributes,
  ParcelUpdateAttributes,
  PriceCalculationErrorLog,
} from 'typings/shipment';
import { BoxMetadata, ShipmentMetadata } from '@client/core/corelogic/ports/shipment.interface';
import { CourierLogo } from '@client/data/courier';
import { isIShipmentListLogsPriceCalculationItem } from './typeGuards';

export default class ShipmentMapper {
  fromAPI(shipment: IShipmentListItem): ShipmentData {
    return {
      id: shipment.id,
      easyshipId: shipment.easyship_shipment_id,
      createdAt: shipment.created_at ?? '',
      orderCreatedAt: shipment.order_created_at ?? '',
      totalCharge: shipment.total_charge ?? 0,
      tax: {
        sales: shipment.sales_tax,
        salesTaxName: shipment.sales_tax_name ?? '',
        provincialSales: shipment.provincial_sales_tax,
        provincialSalesTaxName: shipment.provincial_sales_tax_name ?? '',
      },
      currencyCode: shipment.currency ?? '',
      order: {
        store: {
          name: shipment.store.name ?? '',
          platformId: shipment.store.platform_id ?? 0,
        },
        platform: {
          name: shipment.platform_name ?? '',
          slug: shipment.platform_name_slug ?? '',
          orderNumber: shipment.platform_order_number ?? '',
        },
        eeiReference: shipment.eei_reference ?? '',
        tags: shipment.order_tag_list,
        incoterms: shipment.incoterms ?? 'DDU',
        isInsured: shipment.is_insured,
        insuranceRequestedByBuyer: shipment.insurance_requested_by_buyer,
        buyerNotes: shipment.buyer_notes ?? '',
        sellerNotes: shipment.seller_notes ?? '',
        includeReturnLabel: !!shipment.automated_return_requested,
        parcels: shipment.parcels_attributes.map<ParcelData>((parcel) => ({
          id: parcel.id || '',
          totalSellingPrice: parcel.total_customs_value,
          totalWeight: parcel.total_actual_weight,
          displayWeight: parcel.display_weight,
          weightUnit: parcel.display_weight_unit,
          chargeableWeight: parcel.total_chargeable_weight,
          box: {
            id: parcel.packageable_id ?? '',
            name: parcel.name ?? 'Custom Box',
            length: parcel.package_data.length || undefined,
            width: parcel.package_data.width || undefined,
            height: parcel.package_data.height || undefined,
            isFlatRate: parcel.packageable_type === 'FlatRateBox',
          },
          items: parcel.shipment_items_attributes.map<ItemData>((item) => {
            let categoryHsCode: CategoryData | HsCodeSelection | null = null;

            if (item.item_category_id && item.item_category.name) {
              categoryHsCode = {
                categoryId: item.item_category_id,
                categoryDescription: item.item_category.name,
              };
            }

            if (item.hs_code && item.hs_code_description) {
              categoryHsCode = {
                ...categoryHsCode,
                hsCodeNumber: item.hs_code,
                hsCodeDescription: item.hs_code_description,
              };
            }

            const itemData: ItemData = {
              id: item.id,
              productId: item.product_id ?? '',
              description: item.description ?? '',
              quantity: item.quantity,
              sku: item.company_sku ?? '',
              categoryHsCode,
              unitSellingPrice: item.declared_customs_value ?? '',
              currencyCode: item.declared_currency ?? '',
              pickLocation: item.pick_location ?? '',
              hasBattery:
                (item.contains_battery_pi965 ||
                  item.contains_battery_pi966 ||
                  item.contains_battery_pi967) ??
                false,
              originCountryAlpha2: item.origin_country_alpha2 || '',
              containsBatteryPi966: item.contains_battery_pi966 ?? false,
              containsBatteryPi967: item.contains_battery_pi967 ?? false,
              containsLiquids: item.contains_liquids ?? false,
              platformOrderItemId: item.platform_order_item_id ?? '',
            };

            return itemData;
          }),
        })),
        totalActualWeight: shipment.total_actual_weight ?? 0,
        totalChargeableWeight: shipment.total_volumetric_weight ?? 0,
      },
      senderAddress: {
        id: this.getSenderAddressId(shipment),
        addressLine1: shipment.origin_address?.line_1 ?? '',
        addressLine2: shipment.origin_address?.line_2 ?? '',
        companyName: shipment.origin_address?.company_name ?? '',
        phoneNumber: shipment.origin_address?.contact_phone ?? '',
        email: shipment.origin_address?.contact_email ?? '',
        country: {
          id: shipment.origin_address?.country_id ?? -1,
          name: shipment.origin_country?.simplified_name ?? '',
          alpha2: shipment.origin_country?.alpha2 ?? '',
        },
        postalCode: shipment.origin_address?.postal_code ?? '',
        state: shipment.origin_address?.state ?? '',
        city: shipment.origin_address?.city ?? '',
        nickname: shipment.origin_address?.nickname ?? '',
        senderName: shipment.origin_address?.contact_name ?? '',
        useForLater: shipment.origin_address?.use_for_later ?? true,
        hkDistrictId: shipment.origin_address?.hk_district_id ?? -1,
      },
      receiverAddress: {
        addressLine1: shipment.address_line_1 ?? '',
        addressLine2: shipment.address_line_2 ?? '',
        companyName: shipment.destination_company_name ?? '',
        phoneNumber: shipment.phone_number ?? '',
        email: shipment.email_address ?? '',
        country: {
          id: shipment.destination_country_id ?? -1,
          name: shipment.destination_country?.simplified_name ?? '',
          alpha2: shipment.destination_country?.alpha2 ?? '',
        },
        postalCode: shipment.postal_code ?? '',
        state: shipment.state ?? '',
        city: shipment.city ?? '',
        receiverName: shipment.destination_name ?? '',
        taxId: shipment.consignee_tax_id ?? '',
        isSetAsResidentialAddress: !!shipment.set_as_residential,
        isIdentifiedAsResidentialAddress: this.mapToResidentialIdentification(
          shipment.identified_as_residential
        ),
      },
      courierServiceSelectedId: shipment.courier_id,
      rates:
        shipment.price_comparison?.rates.map<CourierServiceRateData>((rate) => ({
          courierService: {
            id: rate.courier_id,
            displayName: rate.courier_display_name,
            name: rate.courier_name,
            umbrellaName: rate.courier_umbrella_name ?? '',
            courierSlug: rate.courier_logo,
            deliveryTime: { min: rate.min_delivery_time, max: rate.max_delivery_time },
            trackingQuality: rate.tracking_rating,
            rating: { value: rate.courier_surveys_rating, count: rate.courier_surveys_count },
            remarks: rate.courier_remarks ?? '',
            handoverOptions: (rate.available_handover_options ?? '').split(',') as HandoverOption[],
            isEasyshipCourier: rate.is_easyship_courier,
          },
          discount: {
            amount: rate.discount?.amount ?? 0,
            code: rate.discount?.code ?? '',
          },
          isDomestic: shipment.origin_country?.alpha2 === shipment.destination_country.alpha2,
          incoterms: rate.effective_incoterms as Incoterms,
          importTaxCharges:
            rate.effective_incoterms === 'DDU'
              ? rate.estimated_import_tax + rate.estimated_import_duty
              : rate.import_tax_charge + rate.import_duty_charge + rate.ddp_handling_fee,
          feeGroups: [
            {
              name: 'Shipping cost',
              fees: [
                {
                  name: {
                    id: 'shipments.list.cost.shipping',
                  },
                  value: rate.shipment_charge,
                },
                {
                  name: {
                    id: 'shipments.list.cost.discount',
                    values: { code: rate.discount?.code ?? 'NA' },
                  },
                  value: -(rate.discount?.amount ?? 0),
                },
              ],
            },
            {
              name: 'Shipping cost charges',
              fees: [
                {
                  name: {
                    id: 'shipments.list.cost.fuel',
                  },
                  value: rate.fuel_surcharge,
                },
                {
                  name: {
                    id: 'shipments.list.cost.pickup',
                  },
                  value: rate.minimum_pickup_fee,
                },
              ],
            },
            {
              name: 'Courier surcharges',
              fees: [
                {
                  name: {
                    id: 'shipments.list.cost.remote',
                  },
                  value: rate.remote_area_surcharge,
                },
                {
                  name: {
                    id: 'shipments.list.cost.residential',
                  },
                  value: this.residentialFees(shipment, rate),
                },
                {
                  name: {
                    id: 'shipments.list.cost.oversized',
                  },
                  value: rate.oversized_surcharge,
                },
                {
                  name: {
                    id: 'shipments.list.cost.additional',
                  },
                  value: rate.additional_services_surcharge,
                },
                ...(rate.other_surcharges?.details ?? []).map(({ name, fee }) => ({
                  name: {
                    id: '',
                    defaultMessage: name,
                  },
                  value: fee,
                })),
              ],
            },
            {
              name: 'Insurance',
              fees: [
                {
                  name: {
                    id: 'shipments.list.cost.insurance',
                  },
                  value: rate.insurance_fee,
                },
              ],
            },
            {
              name: 'Warehouse charges',
              fees: [
                {
                  name: {
                    id: 'shipments.list.cost.warehouse',
                  },
                  value: rate.warehouse_handling_fee,
                },
              ],
            },
            {
              name: 'Taxes and duties',
              fees: [
                {
                  name: {
                    id: 'shipments.list.cost.tax',
                  },
                  value: rate.import_tax_charge,
                },
                {
                  name: {
                    id: 'shipments.list.cost.duty',
                  },
                  value: rate.import_duty_charge,
                },
                {
                  name: {
                    id: 'shipments.list.cost.ddp-handling',
                  },
                  value: rate.ddp_handling_fee,
                },
              ],
            },
          ],
          domesticTaxes: {
            salesTax: rate.sales_tax,
            provincialSalesTax: rate.provincial_sales_tax,
          },
          retailDiscount: rate.retail_discount ?? 0,
          totalCharges: rate.total_charge,
          chargeableWeightCalculationMethod: rate.chargeable_weight_calculation_method || undefined,
          alerts: this.getCourierServiceAlerts(shipment, rate),
          hasResidentialFees: this.residentialFees(shipment, rate) > 0,
          paymentRecipient: rate.payment_recipient,
          currency: rate.currency,
          isCheapest: rate.cost_rank === 1,
          isFastest: rate.delivery_time_rank === 1,
          isBestValue: rate.value_for_money_rank === 1,
          cubicTier: !Number.isNaN(Number(rate.cubic_tier)) ? Number(rate.cubic_tier) : 0,
          totalChargeableWeight: rate.total_chargeable_weight,
        })) ?? [],
      courierAlerts: shipment.courier_alerts ?? [],
      buyerSelection: {
        courier: {
          name: shipment.buyer_selected_courier_data?.name ?? '',
        },
        senderCountryPrice: {
          shippingCost: shipment.buyer_selected_courier_data?.origin_shipping_cost ?? 0,
          importTaxCharge: shipment.buyer_selected_courier_data?.origin_import_tax_charge ?? 0,
          importDutyCharge: shipment.buyer_selected_courier_data?.origin_import_duty_charge ?? 0,
          currencyCode: shipment.buyer_selected_courier_data?.origin_currency ?? '',
        },
        receiverCountryPrice: {
          shippingCost: +(shipment.buyer_selected_courier_data?.shipping_cost ?? 0),
          importTaxCharge: shipment.buyer_selected_courier_data?.import_tax_charge ?? 0,
          importDutyCharge: shipment.buyer_selected_courier_data?.import_duty_charge ?? 0,
          currencyCode: shipment.buyer_selected_courier_data?.currency ?? '',
        },
      },
      deliveryTime: {
        min: Number(shipment.min_delivery_time),
        max: Number(shipment.max_delivery_time),
      },
      additionalService: this.getAdditionalServices(shipment),
      hasMissingDimensions: shipment.has_missing_dimensions ?? false,
      effectiveIncoterms: shipment.effective_incoterms,
      isAboveThreshold: shipment.is_above_threshold,
      courierSupportedIncoterms: shipment.courier?.supported_incoterms,
      apiAddressesErrors: this.getApiAddressesErrors(shipment),
      apiOrderErrors: this.geApiOrderErrors(shipment),
      isValidToShip: shipment.is_valid_to_ship,
      collectState: shipment.collect_state ?? 'none',
      courier: {
        name: shipment.courier?.name,
        umbrellaName: shipment.courier?.umbrella_name,
      },
      displayStateNotMatchPostalCodeWarning: shipment.display_state_not_match_postal_code_warning,
      tiktokOrderSplitOrMerged: shipment.tiktok_order_split_or_merged,
    };
  }

  toAPI(
    shipment: Partial<ShipmentFormData>,
    metadata: ShipmentMetadata
  ): IShipmentSubsetAddresses & IShipmentSubsetOrder {
    const shipmentPayload: Partial<IShipmentSubsetAddresses & IShipmentSubsetOrder> = {};

    if (shipment.receiverAddress) {
      Object.assign(shipmentPayload, this.generateReceiverAddress(shipment.receiverAddress));
    }

    if (shipment.senderAddress) {
      Object.assign(shipmentPayload, this.generateSenderAddress(shipment.senderAddress));
    }

    if (shipment.order) {
      Object.assign(shipmentPayload, this.generateOrderPayload(shipment.order, metadata));
    }

    return shipmentPayload as IShipmentSubsetAddresses & IShipmentSubsetOrder;
  }

  private generateReceiverAddress(
    receiverAddress: ReceiverAddressEditData
  ): Partial<IShipmentSubsetAddresses> {
    return {
      destination_name: receiverAddress.receiverName,
      destination_company_name: receiverAddress.companyName,
      address_line_1: receiverAddress.addressLine1,
      address_line_2: receiverAddress.addressLine2,
      city: receiverAddress.city,
      destination_country_id: receiverAddress.countryId,
      phone_number: receiverAddress.phoneNumber,
      email_address: receiverAddress.email,
      postal_code: receiverAddress.postalCode,
      state: receiverAddress.state,
      set_as_residential: receiverAddress.isSetAsResidentialAddress,
      consignee_tax_id: receiverAddress.taxId,
    };
  }

  private generateSenderAddress(
    senderAddress: SenderAddressEditData
  ): Partial<IShipmentSubsetAddresses> {
    const result: Partial<IShipmentSubsetAddresses> = {};

    result.origin_country_id = senderAddress.countryId;

    if (senderAddress.id) {
      result.origin_address_id = senderAddress.id;
    } else {
      result.origin_address = {
        line_1: senderAddress.addressLine1,
        line_2: senderAddress.addressLine2,
        contact_name: senderAddress.senderName,
        company_name: senderAddress.companyName,
        contact_phone: senderAddress.phoneNumber,
        contact_email: senderAddress.email,
        postal_code: senderAddress.postalCode,
        city: senderAddress.city,
        state: senderAddress.state,
        use_for_later: senderAddress.useForLater,
        country_id: senderAddress.countryId,
      };

      if (senderAddress.hkDistrictId && senderAddress.hkDistrictId > 0) {
        result.origin_address.hk_district_id = senderAddress.hkDistrictId;
      }
    }

    return result;
  }

  private generateOrderPayload(
    order: OrderEditData,
    metadata: ShipmentMetadata
  ): Omit<IShipmentSubsetOrder, 'id'> {
    const parcelAttributes: ParcelAttributes[] = [];
    metadata.parcelIdsToDelete.forEach((parcelId) => {
      if (parcelId) {
        const attributes: ParcelDeleteAttributes = {
          id: parcelId,
          _destroy: true,
        };
        parcelAttributes.push(attributes);
      }
    });
    order.parcels.forEach((parcel, index) => {
      const attributes: ParcelUpdateAttributes = {
        id: parcel.id,
        ...this.generatePackageDataPayload(parcel.box, metadata.parcels[index].box),
        total_actual_weight: parcel.totalWeight || 0,
        display_weight_unit: parcel.weightUnit,
        display_weight: parcel.totalWeight || 0,
        shipment_items_attributes: parcel.items.map<ItemUpdateAttributes>((item) => ({
          id: item.id,
          product_id: item.productId,
          description: item.description,
          declared_customs_value: item.unitSellingPrice || 0,
          declared_currency: item.currencyCode,
          company_sku: item.sku,
          item_category_id:
            item.categoryHsCode && 'categoryId' in item.categoryHsCode
              ? item.categoryHsCode.categoryId
              : null,
          hs_code:
            item.categoryHsCode && 'hsCodeNumber' in item.categoryHsCode
              ? item.categoryHsCode.hsCodeNumber
              : null,
          pick_location: item.pickLocation,
          quantity: item.quantity || 0,
          height: item.dimensions?.height,
          length: item.dimensions?.length,
          width: item.dimensions?.width,
          origin_country_alpha2: item.originCountryAlpha2 || null,
          contains_battery_pi966: item.containsBatteryPi966,
          contains_battery_pi967: item.containsBatteryPi967,
          contains_liquids: item.containsLiquids,
          platform_order_item_id: item.platformOrderItemId || null,
        })),
      };
      parcelAttributes.push(attributes);
    });

    return {
      incoterms: order.incoterms,
      additional_services: this.generateAdditionaServicesPayload(order.optionalService),
      automated_return_requested: order.includeReturnLabel,
      broker_id: this.generateBrokerIdPayload(order.optionalService),
      eei_reference: order.eeiReference,
      is_insured: order.isInsured,
      order_tag_list: order.tags,
      platform_name: order.platform.name,
      platform_order_number: order.platform.orderNumber,
      seller_notes: order.sellerNotes,
      order_data: this.generateOrderDataPayload(order.optionalService),
      parcels_attributes: parcelAttributes,
    };
  }

  private generateOrderDataPayload(
    optionalService:
      | NoOptionalService
      | UPSOptionalService
      | FedexOptionalService
      | AuPostMyPostOptionalService
  ): IShipmentOrderData {
    if (optionalService.value === CourierLogo.Fedex) {
      return {
        fedex_duties_payment_type: optionalService.taxDuties.payor,
        fedex_duties_payment_account_number: optionalService.taxDuties.accountNumber,
        fedex_shipping_charges_payment_type: optionalService.shippingCost.payor,
        fedex_shipping_charges_account_number: optionalService.shippingCost.accountNumber,
        fedex_home_delivery_certain_date:
          optionalService.homeDeliveryPremium?.value === 'fedex_home_delivery_premium_date_certain'
            ? optionalService.homeDeliveryPremium.date
            : undefined,
        fedex_priority_alert_content:
          optionalService.priorityAlert.value === true
            ? optionalService.priorityAlert.notes
            : undefined,
        fedex_priority_alert_enhancement_type:
          optionalService.priorityAlert.value && optionalService.priorityAlert.hasPriorityAlertPlus
            ? 'PRIORITY_ALERT_PLUS'
            : undefined,
        fedex_b13a_filing_option: optionalService.b13a?.filingOption ?? 'NOT_REQUIRED',
        fedex_b13a_filing_permit_number: optionalService.b13a?.permitNumber ?? '',
        fedex_b13a_filing_option_export_compliance_statement:
          optionalService.b13a?.exportComplianceStatement ?? '',
      };
    }
    if (optionalService.value === CourierLogo.MyPost) {
      return {
        australia_post_signature_on_delivery: optionalService.hasSignatureOnDelivery,
        australia_post_extra_cover_checked: optionalService.hasExtraCoverChecked,
        australia_post_extra_cover: optionalService.hasExtraCover,
      };
    }
    return {};
  }

  private generateBrokerIdPayload(
    optionalService:
      | NoOptionalService
      | UPSOptionalService
      | FedexOptionalService
      | AuPostMyPostOptionalService
  ): string | null {
    if (optionalService.value === CourierLogo.Fedex) {
      return optionalService.brokerId;
    }
    return null;
  }

  private generateAdditionaServicesPayload(
    optionalService:
      | NoOptionalService
      | UPSOptionalService
      | FedexOptionalService
      | AuPostMyPostOptionalService
  ): AdditionalServicesOption[] {
    let additionalServices: AdditionalServicesOption[] = [];
    if (optionalService.value === CourierLogo.Fedex) {
      additionalServices = this.generateFedexServicesListPayload(optionalService);
    }
    if (optionalService.value === CourierLogo.Ups) {
      additionalServices = this.generateUpsServicesListPayload(optionalService);
    }
    if (optionalService.value === CourierLogo.MyPost) {
      additionalServices = this.generateAuPostMyPostOptionalServicesListPayload(optionalService);
    }
    if (optionalService.value === CourierLogo.MyPost) {
      additionalServices.push(
        ...this.generateAuPostMyPostOptionalServicesListPayload(optionalService)
      );
    }
    return additionalServices;
  }

  private generateFedexServicesListPayload(service: FedexOptionalService): FedexOption[] {
    const servicesList: FedexOption[] = [];
    const batteryOption = service.battery === 'fedex_no_battery' ? '' : service.battery;

    servicesList.push(service.deliveryConfirmation);
    batteryOption && servicesList.push(batteryOption);
    service.priorityAlert.value && servicesList.push('fedex_priority_alert');
    service.hasSaturdayDelivery && servicesList.push('fedex_saturday_delivery');
    service.hasSaturdayPickup && servicesList.push('fedex_saturday_pickup');
    service.hasThirdPartyConsignee && servicesList.push('fedex_third_party_consignee');
    if (service.hasHomeDeliveryPremium) {
      service.homeDeliveryPremium?.value && servicesList.push(service.homeDeliveryPremium?.value);
    }

    return servicesList;
  }

  private generateUpsServicesListPayload(service: UPSOptionalService): UPSOption[] {
    const servicesList: UPSOption[] = [];

    const deliveryConfirmation =
      service.deliveryConfirmation === 'ups_delivery_confirmation_no_signature'
        ? ''
        : service.deliveryConfirmation;

    deliveryConfirmation && servicesList.push(deliveryConfirmation);
    service.hasAdditionalHandling && servicesList.push('ups_additional_handling');
    service.hasLargePackage && servicesList.push('ups_large_package');
    service.hasSaturdayDelivery && servicesList.push('ups_saturday_delivery');
    service.hasShipperRelease && servicesList.push('ups_shipper_release');

    return servicesList;
  }

  private generateAuPostMyPostOptionalServicesListPayload(
    service: AuPostMyPostOptionalService
  ): AuPostMyPostBusinessOptions[] {
    const servicesList: AuPostMyPostBusinessOptions[] = [];
    service.hasSignatureOnDelivery && servicesList.push('australia_post_signature_on_delivery');
    service.hasExtraCover && servicesList.push('australia_post_extra_cover');

    return servicesList;
  }

  private generatePackageDataPayload(
    box: BoxEditData,
    metadata: BoxMetadata
  ): BoxUpdateData | CustomBoxUpdateData {
    const packageableType = metadata.isFlatRate ? 'FlatRateBox' : 'Box';
    return {
      packageable_type: box.id ? packageableType : null,
      packageable_id: box.id || null,
      package_data: {
        length: box.length || null,
        width: box.width || null,
        height: box.height || null,
      },
    };
  }

  private mapToResidentialIdentification(
    identifiedAsResidential: IdentifiedAsResidential
  ): ResidentialIdentification {
    if (identifiedAsResidential === true) {
      return 'positive';
    }
    if (identifiedAsResidential === false) {
      return 'negative';
    }
    return 'unknown';
  }

  private residentialFees(shipment: IShipmentListItem, rate: IRateItem): number {
    const isIdentifiedAsResidentialAddress = this.mapToResidentialIdentification(
      shipment.identified_as_residential
    );

    if (isIdentifiedAsResidentialAddress === 'negative') {
      return 0;
    }
    if (shipment.set_as_residential) {
      return rate.residential_discounted_fee;
    }
    return 0;
  }

  private getCourierServiceAlerts(shipment: IShipmentListItem, rate: IRateItem): TranslationKey[] {
    const alerts: TranslationKey[] = [];
    const isDiscountInapplicable = !!shipment.discount.code && shipment.discount.amount <= 0;
    const isLYOC = !rate.is_easyship_courier;
    const showIossNote =
      !!shipment.regulatory_identifiers?.ioss && rate.import_tax_non_chargeable > 0;
    const showUninsurableMessage = shipment.is_insured && rate.insurance_fee === 0;
    const isDomesticShipment = shipment.origin_country_id === shipment.destination_country_id;

    if (isDiscountInapplicable) {
      if (isLYOC) {
        alerts.push({
          id: 'shipments.list.cost.no-discount-lyoc',
        });
      } else {
        alerts.push({
          id: 'shipments.list.cost.no-discount',
        });
      }
    }
    if (showIossNote) {
      alerts.push({
        id: 'shipments.list.cost.ioss-note',
        values: { amount: rate.import_tax_non_chargeable },
      });
    } else if (!isDomesticShipment) {
      if (rate.effective_incoterms === 'DDU') {
        if (rate.is_above_threshold) {
          alerts.push({
            id: 'shipments.list.cost.ddu-above-threshold',
            values: { charged: rate.estimated_import_tax + rate.estimated_import_duty },
          });
        } else {
          alerts.push({ id: 'shipments.list.cost.ddu-below-threshold' });
        }
      } else if (rate.effective_incoterms === 'DDP') {
        if (rate.is_above_threshold) {
          alerts.push({ id: 'shipments.list.cost.ddp-above-threshold' });
        } else {
          alerts.push({ id: 'shipments.list.cost.ddp-below-threshold' });
        }
      }
    } else {
      alerts.push({ id: 'shipments.list.cost.ddp-domestic' });
    }
    if (showUninsurableMessage) {
      alerts.push({
        id: 'shipments.list.cost.uninsurable',
        values: { country: shipment.destination_country.name },
      });
    }

    return alerts;
  }

  private getSenderAddressId(shipment: IShipmentListItem): string {
    function isShipmentAddress(
      address: IDraftAddress | IShipmentAddress
    ): address is IShipmentAddress {
      return (address as IShipmentAddress).id !== undefined;
    }
    if (shipment.origin_address && isShipmentAddress(shipment.origin_address)) {
      return shipment.origin_address.id;
    }
    return '';
  }

  private getAdditionalServices(
    shipment: IShipmentListItem
  ): UpsServiceData | FedexServiceData | AuPostMyPostServiceData | undefined {
    function hasFedexOptions(options: AdditionalServicesOption[]): options is FedexOption[] {
      return options.some((service) => fedexOptions.includes(service as FedexOption));
    }
    function hasUPSOptions(options: AdditionalServicesOption[]): options is UPSOption[] {
      return options.some((service) => upsOptions.includes(service as UPSOption));
    }
    function hasAuPostMyPostOptions(
      options: AdditionalServicesOption[]
    ): options is AuPostMyPostBusinessOptions[] {
      return options.some((service) =>
        auPostMyPostBusinessOptions.includes(service as AuPostMyPostBusinessOptions)
      );
    }

    if (hasFedexOptions(shipment.additional_services)) {
      const fedexService: FedexServiceData = {
        shippingCost: {
          payor: shipment.order_data?.fedex_shipping_charges_payment_type ?? 'SENDER',
          accountNumber: shipment.order_data?.fedex_shipping_charges_account_number ?? '',
        },
        taxDuties: {
          payor: shipment.order_data?.fedex_duties_payment_type ?? 'SENDER',
          accountNumber: shipment.order_data?.fedex_duties_payment_account_number ?? '',
        },
        deliveryConfirmation:
          (shipment.additional_services.find((service) =>
            (fedexDeliverySignatureOptions as Readonly<string[]>).includes(service)
          ) as FedexDeliverySignatureOption) ?? 'fedex_delivery_confirmation_no_signature_required',
        battery:
          (shipment.additional_services.find((service) =>
            (fedexBatteryOptions as Readonly<string[]>).includes(service)
          ) as FedexBatteryOption) ?? 'fedex_no_battery',
        hasSaturdayDelivery: shipment.additional_services.includes('fedex_saturday_delivery'),
        hasSaturdayPickup: shipment.additional_services.includes('fedex_saturday_pickup'),
        hasThirdPartyConsignee: shipment.additional_services.includes(
          'fedex_third_party_consignee'
        ),
        hasHomeDeliveryPremium: shipment.additional_services.some((additionalService) =>
          fedexHomeDeliveryPremiumOptions.includes(
            additionalService as FedexHomeDeliveryPremiumOption
          )
        ),
        priorityAlert: { value: false },
        brokerId: shipment.broker_id ?? '',
      };

      const hasPriorityAlert = shipment.additional_services.includes('fedex_priority_alert');
      const hasPriorityAlertPlus =
        shipment.order_data?.fedex_priority_alert_enhancement_type === 'PRIORITY_ALERT_PLUS';
      if (hasPriorityAlert) {
        fedexService.priorityAlert = {
          hasPriorityAlertPlus,
          value: true,
          notes: shipment.order_data?.fedex_priority_alert_content ?? '',
        };
      }

      if (fedexService.hasHomeDeliveryPremium) {
        const hasHomeDeliveryDateCertain = shipment.additional_services.includes(
          'fedex_home_delivery_premium_date_certain'
        );
        const hasHomeDeliveryAppointment = shipment.additional_services.includes(
          'fedex_home_delivery_premium_appointment'
        );
        const hasHomeDeliveryEvening = shipment.additional_services.includes(
          'fedex_home_delivery_premium_evening'
        );
        if (hasHomeDeliveryDateCertain) {
          fedexService.homeDeliveryPremium = {
            value: 'fedex_home_delivery_premium_date_certain',
            date: shipment.order_data?.fedex_home_delivery_certain_date ?? '',
          };
        } else if (hasHomeDeliveryAppointment) {
          fedexService.homeDeliveryPremium = {
            value: 'fedex_home_delivery_premium_appointment',
          };
        } else if (hasHomeDeliveryEvening) {
          fedexService.homeDeliveryPremium = {
            value: 'fedex_home_delivery_premium_evening',
          };
        }
      }

      fedexService.b13a = {
        filingOption: shipment.order_data?.fedex_b13a_filing_option ?? 'NOT_REQUIRED',
        exportComplianceStatement:
          shipment.order_data?.fedex_b13a_filing_option_export_compliance_statement ?? '',
        permitNumber: shipment.order_data?.fedex_b13a_filing_permit_number ?? '',
      };
      return fedexService;
    }
    if (hasUPSOptions(shipment.additional_services)) {
      const upsService: UpsServiceData = {
        deliveryConfirmation:
          (shipment.additional_services.find((service) =>
            (upsDeliveryConfirmationOptions as Readonly<string[]>).includes(service)
          ) as UPSDeliveryConfirmationOption) ?? 'ups_delivery_confirmation_no_signature',
        hasSaturdayDelivery: shipment.additional_services.includes('ups_saturday_delivery'),
        hasShipperRelease: shipment.additional_services.includes('ups_shipper_release'),
        hasLargePackage: shipment.additional_services.includes('ups_large_package'),
        hasAdditionalHandling: shipment.additional_services.includes('ups_additional_handling'),
      };
      return upsService;
    }

    if (hasAuPostMyPostOptions(shipment.additional_services)) {
      const auPostMyPostService: AuPostMyPostServiceData = {
        hasSignatureOnDelivery: shipment.additional_services.includes(
          'australia_post_signature_on_delivery'
        ),
        hasExtraCoverChecked: !!shipment.order_data?.australia_post_extra_cover,
        hasExtraCover: shipment.order_data?.australia_post_extra_cover || '',
      };
      return auPostMyPostService;
    }

    return undefined;
  }

  private geApiOrderErrors(shipment: IShipmentListItem): PriceCalculationErrorLog {
    if (!isIShipmentListLogsPriceCalculationItem(shipment.logs.price_calculation)) {
      return {};
    }

    const parcelAndShipmentItemErrors = this.extractUniqueErrorFields({
      ...shipment.logs.price_calculation.parcels,
      ...shipment.logs.price_calculation.shipment_items,
    });

    return {
      ...shipment.logs.price_calculation.shipment,
      ...parcelAndShipmentItemErrors,
    };
  }

  private getApiAddressesErrors(shipment: IShipmentListItem): PriceCalculationErrorLog {
    if (!isIShipmentListLogsPriceCalculationItem(shipment.logs.price_calculation)) {
      return {};
    }

    return shipment.logs.price_calculation.address ?? {};
  }

  private extractUniqueErrorFields<T extends Record<string, PriceCalculationErrorLog>>(
    items: T
  ): PriceCalculationErrorLog {
    return Object.values(items).reduce(
      (acc, item) => ({
        ...acc,
        ...item,
      }),
      {}
    );
  }
}
