import angular from 'angular';

import easyshipButton from '@client/core/components/buttons/button';
import easyshipRowButton from '@client/core/components/buttons/row-button';
import trustAsHtml from '@client/core/filters/trust-as-html.filter';
import subscriptionService from '@client/src/global/services/subscription';
import userService from '@client/src/global/User';

import { AngularSubscriptionTableCardComponent } from '@client/core/components/wrappers/components/AngularSubscriptionTableCardComponent';
import { PlanListComponent } from './plan-list/plan-list.component';
import { ShipmentsDoneCardComponent } from './shipments-done-card/shipments-done-card.component';
import { DeleteMemberCardComponent } from './delete-member-card/delete-member-card.component';
import { DeleteInviteMemberCardComponent } from './delete-invite-member-card/delete-invite-member-card.component';
import { ConfirmDeleteMemberCardComponent } from './confirm-delete-member-card/confirm-delete-member-card.component';
import { UpgradePlanCardComponent } from './upgrade-plan-card/upgrade-plan-card.component';
import { SubscriptionInfoCardComponent } from './info-card/info-card.component';
import { ChangePlanIntegrationComponent } from './change-plan-integration/change-plan-integration.component';
import { DowngradePlanCardComponent } from './downgrade-plan-card/downgrade-plan-card.component';
import { EnjoyFreeTrialCardComponent } from './enjoy-free-trial-card/enjoy-free-trial-card.component';
import { EnterpriseCallCardComponent } from './enterprise-call-card/enterprise-call-card.component';
import { CallScheduledCardComponent } from './call-scheduled-card/call-scheduled-card.component';

const MODULE_NAME = 'app.global.components.subscription';

angular
  .module(MODULE_NAME, [
    'pascalprecht.translate',
    subscriptionService,
    'core.service.mixpanel',
    userService,
    'app.global.services.onboarding-checklist',
    'app.global.courier-accounts',
    easyshipButton,
    easyshipRowButton,
    trustAsHtml,
  ])
  .component('esSubscriptionPlanList', PlanListComponent)
  .component('esShipmentsDoneCard', ShipmentsDoneCardComponent)
  .component('esDeleteMemberCard', DeleteMemberCardComponent)
  .component('esDeleteInviteMemberCard', DeleteInviteMemberCardComponent)
  .component('esConfirmDeleteMemberCard', ConfirmDeleteMemberCardComponent)
  .component('esSubscriptionTableCard', AngularSubscriptionTableCardComponent)
  .component('esSubscriptionUpgradePlanCard', UpgradePlanCardComponent)
  .component('esSubscriptionDowngradePlanCard', DowngradePlanCardComponent)
  .component('esSubscriptionEnjoyFreeTrialCard', EnjoyFreeTrialCardComponent)
  .component('esSubscriptionInfoCard', SubscriptionInfoCardComponent)
  .component('esChangePlanIntegration', ChangePlanIntegrationComponent)
  .component('esEnterpriseCallCard', EnterpriseCallCardComponent)
  .component('esCallScheduledCard', CallScheduledCardComponent);

export default MODULE_NAME;
