import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { react2angular } from 'react2angular';
import React, { lazy, Suspense } from 'react';
import { ManageTeamProps } from '@client/src/account/team/components/types';
import { Pulse } from 'easyship-components';

const ManageTeamListComponent = lazy(
  () => import('@client/src/account/team/components/ManageTeamList')
);

function WrappedManageTeamListComponent(props: ManageTeamProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <Suspense fallback={<Pulse show />}>
          <ManageTeamListComponent {...props} />
        </Suspense>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularManageTeamListComponent = react2angular(
  WrappedManageTeamListComponent,
  ['onUpgradePlan', 'googleAnalytics'],
  []
);
